
import {User as UserInterface} from './interface/User';
import {Customer} from './Customer';

export class User implements UserInterface {
    [key: string]: any

    id: number = 0;
    firstname: string = '';
    lastname: string = '';
    email: string = '';
    clients: String[] | [] = [];
    admin: boolean = false;
    image: string = '';
    deletedImage: boolean = false;
    visibility: string | null = null;
    cguValidatedAt: Date = new Date();
    societe: string = '';
    hiddenClients: Customer[] | [] = [];
    notificationMyShipmentMessage: boolean | null = false;
    notificationMyShipmentAttachedFile: boolean | null = false;
    notificationMyProofPhoto: boolean | null = false;
    notificationMyInvoiceAttachedFile: boolean | null = false;
    notificationMyInvoiceComment: boolean | null = false;
    notificationMyCustomsDeclaration: boolean | null = false;
    notificationMyShipmentTransportDelay: boolean | null = false;
    notificationMyShipmentEtdDelay: boolean | null = false;
    notificationMyShipmentDerudderInvoice: boolean | null = false;
    // address: Address[] = []
    trafficCodes: string | null = null;
    shipmentSubscriptions: string | null = null;
    apiToken: string | null = null;
    apiClientToken: string | null = null;
    apiClientUrl: string | null = null;
    apiClientHeader: string | null = null;
    lastLogin: Date | null;
    groups: string[] = [];
    plainPassword: string = '';
    clientNames: string[] = [];
    projects: string[] = [];
    profiles: string[] = [];
    userJupiter: string | null = null;
    userMyrudder: string | null = null;
    userWorkflow: string | null = null;

    constructor(
        id: number = 0,
        firstname: string = '',
        lastname: string = '',
        email: string = '',
        lastLoggedAt: Date | null = null,
        groups: string[] = [],
    ) {
        this.id = id;
        this.firstname = firstname;
        this.lastname = lastname;
        this.email = email;
        this.lastLogin = lastLoggedAt;
        this.groups = groups;
    }

}

export type UserType = User;