import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Row, Col, Card, Container, Button} from 'react-bootstrap';

import logo from "../../assets/images/logo-derudder.svg"

const NotFound = () => {
    const navigate = useNavigate()

    React.useEffect(() => {
        if (document.body) document.body.classList.add('authentication-bg', 'authentication-bg-pattern');

            return () => {
                if (document.body) document.body.classList.remove('authentication-bg', 'authentication-bg-pattern');
            };
    }, []);

    const handleBack = () => {
        navigate(-1);
    }

    return (
        <>
            <div className="account-pages mt-5 mb-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={4}>
                            <Card className="bg-pattern bg-white">
                                <Card.Body className="p-4">
                                    {/* logo */}
                                    <div className="auth-logo">
                                        <Link to="/" className="logo logo-dark text-center">
                                            <span className="logo-lg">
                                                <img src={logo} alt="" height="22" />
                                                Jupiter
                                            </span>
                                        </Link>
                                    </div>
                                    <div className="text-center mt-4">
                                        {/*<h1 className="text-error">404</h1>*/}
                                        <h3 className="mt-3 mb-2 text-primary">Page non trouvée</h3>
                                        <p className="text-muted mb-3">
                                            Désolé mais la page que vous cherchez est introuvable. Veuillez contacter votre administrateur si vous pensez qu'il s'agit d'une erreur.
                                        </p>

                                        <Button onClick={handleBack} className="btn btn-primary waves-effect waves-light mb-3">
                                            <i className={'fas fa-arrow-left me-2'}></i>
                                            Retourner à la page précédente
                                        </Button>

                                        <Link to="/" className="btn btn-primary waves-effect waves-light">
                                            <i className={'fas fa-home me-2'}></i>
                                            Retourner à l'accueil
                                        </Link>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default NotFound;