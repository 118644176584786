import React, {useEffect} from 'react';
import {renderFilterProps} from '../../../components/ListingController';
import FilterFormsLayout from './FilterFormsLayout';
import {Col, Form, Row} from 'react-bootstrap';
import TextInput from '../../fields/TextInput';
import ButtonComponent from '../../../components/ButtonComponent';
import {useForm} from 'react-hook-form';

interface FilterProps extends renderFilterProps {}

const AccessProfileFilter = ({
    onFilter,
    onReset,
    defaultValues,
}: FilterProps) => {
    const methods = useForm({defaultValues: defaultValues});
    const {
        handleSubmit,
        register,
        reset,
        control,
        watch,
        getFieldState,
    } = methods;

    const activeFilters = Object.entries(watch())
        // retire les champs qui ont leurs valeurs par défaut
        .filter(([key]) => getFieldState(key).isDirty)
    ;

    useEffect(() => {
        reset(defaultValues, {keepDefaultValues: true});
    }, [defaultValues, reset]);

    return (
        <FilterFormsLayout
            activeFilters={activeFilters.length}
            renderForm={(toggleCollapsePanel: any) =>
                <Form onSubmit={handleSubmit(onFilter)}>

                    <Row>
                        <Col lg={4}>
                            <TextInput name={'name'} register={register}
                                       label={'Nom'} containerClass={'mb-3'}
                                       control={control}/>
                        </Col>
                    </Row>

                    <Row className={'justify-content-end'}>
                        <Col lg={8} className={'text-end'}>
                            <ButtonComponent buttonType={'submit'}
                                             buttonLabel={'Rechercher'}
                                             buttonIcon={'fas fa-search'}
                                             handleClickEvent={() => toggleCollapsePanel()}/>

                            <ButtonComponent buttonType={'button'}
                                             buttonLabel={'Réinitialiser'}
                                             buttonIcon={'fas fa-redo-alt'}
                                             classes={'btn-secondary ms-1'}
                                             handleClickEvent={() => {
                                                 reset();
                                                 onReset();
                                                 toggleCollapsePanel();
                                             }}/>
                        </Col>
                    </Row>
                </Form>
            }
        />
    );
};

export default AccessProfileFilter;