import {Outlet} from "react-router-dom";
import LoginLayout from "../layouts/LoginLayout";
import TriptychLayout from "../layouts/TriptychLayout";
import {useState} from "react";
import {FieldValues} from "react-hook-form";
import {TokenContextProvider} from "../context/TokenContext";
import axios from "axios";

const localStorageToken = 'Token';
const locatStorageFullname = 'fullname';
const debug = /*process.env.NODE_ENV === 'development' ? 'app_dev.php/' : */'';

function RootScreen() {
    const [login, setLogin] = useState<string | null>(localStorage.getItem(localStorageToken));
    const [error, setError] = useState<string | null | undefined>(null);

    const handleLogin = (formData: FieldValues) => {
        axios.post(`/${debug}jupiter-login`,
            {
                _username: formData.username,
                _password: formData.password
            }, {
                headers: {Accept: 'application/json'},
            }).then((res) => {
            const fullname = res.data.firstname + ' ' + res.data.lastname;

            localStorage.setItem(localStorageToken, res.data.token);
            localStorage.setItem(locatStorageFullname, fullname);
            setLogin(res.data.token);
        }).catch((err) => {
            console.log(err);
            setError(err);
            return false;
        })
    }
    const handleLogout = () => {
        localStorage.removeItem(localStorageToken);
        localStorage.removeItem(locatStorageFullname);

        setLogin(null);
    }

    if (login) {
        return (
            <TokenContextProvider token={login}>
                <TriptychLayout handleLogout={handleLogout}>
                    <Outlet/>
                </TriptychLayout>
            </TokenContextProvider>
        );
    }

    return (
        <LoginLayout handleLogin={handleLogin} error={error}/>
    );
}

export default RootScreen;
