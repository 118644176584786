import {Identifiable} from './interface/Identifiable';

export enum Profiles {
    ROLE_SUPER_ADMIN = 'Super Administrateur',
    ROLE_ADMIN = 'Administrateur',
    ROLE_USER = 'Utilisateur',
}

export enum ContactClientType {
    COMMERCIAL = 'commercial',
    ACCOUNTING = 'accounting',
    CUSTOMS = 'customs',
    OPERATION = 'operation',
    OPERATIONEXPORT = 'operationExport',
    ITADMIN = 'itAdmin',
    MANAGER = 'manager'
}

export class Group implements Identifiable {

    public groupJupiter: string | null = null;
    public groupMyrudder: string | null = null;
    public groupWorkflow: string | null = null;

    public projects: string[] = [];

    constructor(
        readonly id: number = 0,
        public name: string | null = null,
        public superGroup: boolean = false,
        public pool: boolean = false,
        public contactClientType: string | null = null,
        public customeoTeamName: string | null = null,
        public customeoTeamId: string | null = null,
    ) {
    }
}
