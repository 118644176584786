import React from 'react';
import {useForm, FieldValues} from 'react-hook-form';
import EmailInput from "../../fields/EmailInput";
import PasswordInput from "../../fields/PasswordInput";
import CheckboxInput from "../../fields/CheckboxInput";
import {Alert} from "react-bootstrap";
import ButtonComponent from "../../../components/ButtonComponent";

interface LoginFormProps {
    formClass?: string;
    id?: string,
    formName?: string
    handleLogin: (formData: FieldValues) => void
}

const LoginForm = ({id, formClass, formName, handleLogin}: LoginFormProps) => {
    const [error] = React.useState()

    const methods = useForm({});
    const {
        handleSubmit,
        register,
        control
    } = methods;

    const login = (formData: FieldValues) => {
        handleLogin(formData);
    };

    return (
        <>
            {error ? (
                <Alert variant="danger" className="my-2">
                    {error}
                </Alert>
            ) : null}

            <form id={id} name={formName} onSubmit={handleSubmit((data) => {login(data)})} className={formClass} noValidate>
                <EmailInput
                    name={"username"}
                    containerClass={'form-group mb-3'}
                    register={register}
                    placeholder={"Email"}
                />

                <PasswordInput
                    name={"password"}
                    containerClass={'form-group mb-3'}
                    register={register}
                    placeholder={"Mot de passe"}
                    control={control}
                />

                <CheckboxInput
                    name={"remember_me"}
                    label={"Se souvenir de moi"}
                    containerClass={"form-group mb-3"}
                    register={register}
                />

                <div className="d-grid text-center">
                    <ButtonComponent
                        buttonVariant={"primary"}
                        buttonType={"submit"}
                        buttonLabel={"Connexion"}
                        classes={"btn waves-effect waves-light btn-lg btn-block mt-2"}
                    />
                </div>

            </form>

        </>
    );
};

export default LoginForm;