import {routesPosition, RoutesProps} from "../router.config";

export const dashboardRoutes: RoutesProps = {
    path: '/',
    position: routesPosition.leftSideBar,
    name: 'Accueil',
    icon: 'fas fa-home',
    element: <div> </div>,
    index: true,
};
