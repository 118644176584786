import React, { InputHTMLAttributes } from 'react';
import { Form } from 'react-bootstrap';
import {UseFormRegister} from "react-hook-form";

interface EmailInputProps extends InputHTMLAttributes<HTMLInputElement> {
    containerClass?: string,
    inputClasses?: string,
    label?: string,
    name: string,
    register: UseFormRegister<any>,
    checked?: boolean
}

const CheckboxInput = ({name, label, containerClass, register, inputClasses, checked}: EmailInputProps) => {

    const errors = {[name]: {message: ''}};

    return (
        <Form.Group className={containerClass}>

            <Form.Check
                type={'checkbox'}
                label={label}
                id={name}
                className={inputClasses+' form-check-primary'}
                {...register(name)}
                defaultChecked={checked}
            />

            {errors && errors[name] ? (
                <Form.Control.Feedback type="invalid">
                    {errors[name]['message']}
                </Form.Control.Feedback>
            ) : null}

        </Form.Group>
    );
};

export default CheckboxInput;
