import {normalizedType, Normalizer} from './normalizer';
import {GroupJupiter} from '../../model/GroupJupiter';

const GroupJupiterNormalizer = new class implements Normalizer<GroupJupiter> {

    normalize(data: normalizedType<GroupJupiter>): GroupJupiter {
        const resource = new GroupJupiter(
            data.id,
        );

        resource.group = data.group;
        resource.roles = data.roles;

        return resource;
    }

}();

export {GroupJupiterNormalizer};