import {flattenRoutes, routesPosition, RoutesProps} from '../router.config';
import RootScreen from '../../screens/RootScreen';
import {dashboardRoutes} from './dashboard';
import NotFound from '../../components/Error/NotFound';
import {accessProfileRoute, usersRoute} from './parameters';
import {Profiles} from "../../model/Group";
import {userProfileRoute} from "./profile";

export const mainRoute: RoutesProps = {
    path: '/',
    position: routesPosition.leftSideBar,
    element: <RootScreen/>,
    children: [
        dashboardRoutes,
        usersRoute,
        accessProfileRoute,
        userProfileRoute
    ],
    handle: {
        crumb: 'Dashboard',
    },
    errorElement: <NotFound/>,
    accessControl: [Profiles.ROLE_USER, Profiles.ROLE_ADMIN, Profiles.ROLE_SUPER_ADMIN],
};

const routes = [mainRoute];
const flattenedRoutes = flattenRoutes(routes);

export {routes, flattenedRoutes};
