import {NavLink, useMatches} from "react-router-dom";
import {filterProtectedRoutes, routesPosition, RoutesProps} from "../../navigations/router.config";
import classNames from 'classnames';
import React from "react";
import {Button} from "react-bootstrap";
import { Profiles } from "../../model/Group";

type MainMenuProps = {
    routes: RoutesProps[],
}

/**
 * Composant de lien individuel (render une ligne de menu pour chaque route de l'application)
 * @param item - route
 * @param toggleMenu - méthode optionnelle, présente uniquement sur les liens de premier niveau et permet l'ouverture de sous-menu
 */
function MenuItem(item: RoutesProps, toggleMenu?: any) {

    return (
        <li className={classNames('side-nav-item pb-1')} >
            <NavLink
                to={item.path || ''}
                className={({isActive}) =>
                    isActive ? 'active nav-link' : 'nav-link'
                }
                end
                onClick={toggleMenu ? toggleMenu(item.path) : null}
            >
                {item.icon && (<i className={item.icon}/>)}
                <span className={'side-nav-link-label'}>{item.name}</span>
            </NavLink>
        </li>
    )
}

/**
 * Render le sous-menu (dropdown avec liens de second niveau)
 * @param item - route
 * @param openedRouteName - va déterminer si le sous-menu est ouvert ou fermé
 * @param toggleMenu - permet l'ouverture de sous-menu
 */
function SecondLevelMenu(item: RoutesProps, openedRouteName: RoutesProps['path'], toggleMenu:any) {

    const open = openedRouteName?.replaceAll('/', '') === item.path?.replaceAll('/', '');

    return item.position === routesPosition.leftSideBar ?
            item.children ? item.children.filter((child) => child.position !== routesPosition.hiddenRoutes)
                .map((child, index) => (
                    <React.Fragment key={'subItem-'+index}>
                        {MenuItem(child)}
                    </React.Fragment>
                ))
            : null
    : (
        <li className={classNames('side-nav-item pb-1', { 'menuitem-active': open })}>
            <Button variant={'link'} className={'nav-link text-start'} onClick={toggleMenu(item.path)}>
                <i className={item.icon}/>
                <span className={'side-nav-link-label'}>{item.name}</span>
                <span className={'menu-arrow'}></span>
            </Button>
            <div className={open ? 'sub-menu' : 'sub-menu collapse'}>
                <ul className={'nav-second-level'}>
                    {
                        item.children ? item.children.map((child, index) => (
                                <React.Fragment key={'subItem-'+index}>
                                    {MenuItem(child)}
                                </React.Fragment>
                            )
                        )  : null
                    }
                </ul>
            </div>
        </li>
    )
}

/**
 * Render les liens de premier niveau (avec ou sans enfant)
 * @param routes - tableau des routes de l'application
 * @param openedRouteName - prop nécessaire au composant de sous-menu
 * @param toggleMenu - prop nécessaire aux composants de lien individuel et de sous-menu
 */
function FirstLevelMenu(routes: RoutesProps[], openedRouteName: RoutesProps['path'], toggleMenu: any) {

    return routes
        .map((navElement, index) => {
            const childrenRoutes: RoutesProps[] = [];
            navElement.children && navElement.children.filter((child) => child.position !== routesPosition.hiddenRoutes)
                .map((child) => childrenRoutes.push(child))

            return (
                (navElement.children && childrenRoutes.length > 0)
                    ? (
                        <React.Fragment key={'menu-'+index} >
                            {SecondLevelMenu(navElement, openedRouteName, toggleMenu)}
                        </React.Fragment>
                    ) : (
                        <React.Fragment key={'item-'+index}>
                            {MenuItem(navElement, toggleMenu)}
                        </React.Fragment>
                    )
            )
        })
}

/**
 * Composant principal du menu latéral
 * @param routes - tableau des routes de l'application
 */
function MainMenu({routes}: MainMenuProps) {
    const match = useMatches();
    const initialState = match[1] ? match[1].pathname : '';

    const [open, setOpen] = React.useState<RoutesProps['path']>(initialState);

    /**
    * Ouvre et ferme le sous-menu
    */
    const toggleMenu = (path: RoutesProps['path']) => (e: any) => {
        open === path ? setOpen('') : setOpen(path);
        return false;
    };

    return (
        <>
            <div id="sidebar-menu">
                <ul className="side-menu" id={"main-side-menu"}>
                    {
                        FirstLevelMenu(filterProtectedRoutes(routes, [Profiles.ROLE_ADMIN]), open, toggleMenu)
                    }
                </ul>
            </div>
            <div className="clearfix" />
        </>
    );
}

export default MainMenu;
