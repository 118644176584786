import {renderFormProps} from '../../../FormController';
import {
    AccessProfilePermissionsTable,
} from '../../../../components/app/App/AccessProfilePermissionsTable';
import React from 'react';
import CollapsiblePanel from '../../../../components/CollapsiblePanel';
import {WorkflowRoles} from '../../../../model/enum/roles/Workflow';
import {GroupWorkflow} from '../../../../model/GroupWorkflow';

interface AccessProfilePermissionsProps extends renderFormProps<GroupWorkflow> {
    resource: GroupWorkflow;
}

export const WorkflowPermissions = ({
    register,
    resource,
    setValue,
}: AccessProfilePermissionsProps) => {

    return <CollapsiblePanel
        accordionHeaderClasses={'bg-light'}
        accordionItems={
            [
                {
                    eventKey: '0',
                    header: 'Menu',
                    children:
                        <AccessProfilePermissionsTable
                            id={'import_kanban'}
                            register={register}
                            setValue={setValue}
                            resource={resource}
                            modules={[
                                {
                                    label: 'Kanban Import',
                                    icon: 'fas fa-plane-arrival',
                                    permissions: [
                                        {
                                            rights: [
                                                {
                                                    name: WorkflowRoles.ROLE_WORKFLOW_IMPORT_SHOW,
                                                    value: WorkflowRoles.ROLE_WORKFLOW_IMPORT_SHOW,
                                                    active: true,
                                                }, {
                                                    name: '',
                                                    value: undefined,
                                                    active: false,
                                                },
                                                {
                                                    name: WorkflowRoles.ROLE_WORKFLOW_IMPORT_EDIT,
                                                    value: WorkflowRoles.ROLE_WORKFLOW_IMPORT_EDIT,
                                                    active: true,
                                                },
                                            ], label: 'Permission de base',
                                        },
                                    ],
                                },
                            ]}
                        />,
                },
            ]
        }
    />;
};