import {Identifiable} from './interface/Identifiable';

export class UserMyrudder implements Identifiable {
    user: string | null = null;
    language: string = 'fr';
    phone: string = '';
    enabled: boolean = false;
    admin: boolean = false;
    constructor(
        readonly id: number = 0,
    ) {
    }
}
