import React, {ReactElement, useEffect, useState} from 'react';
import {Identifiable} from '../model/interface/Identifiable';
import {SubmitHandler} from 'react-hook-form/dist/types/form';
import {useMatches} from 'react-router-dom';
import {PaginateProps} from './Paginate';
import useDatasource, {FilterType} from '../hooks/useDatasource';
import {Normalizer} from '../services/normalizer/normalizer';
import axios from 'axios';

export type renderListingProps<ResourceT extends Identifiable> = {
    onFilter?: SubmitHandler<any>,
    resources: Array<ResourceT>,
    isLoading: boolean,
    filters?: FilterType,
    reloadOne?: (resource: ResourceT) => void,
};

export type renderFilterProps = {
    onFilter: SubmitHandler<any>,
    onReset: any,
    defaultValues: FilterType,
}

type ListingControllerProps<ResourceT extends Identifiable> = {
    title?: string | ReactElement
    renderListing: (props: renderListingProps<ResourceT>) => ReactElement
    renderFilter?: (props: renderFilterProps) => ReactElement;
    datasource: string,
    renderPagination?: (props: PaginateProps<ResourceT>) => ReactElement,
    normalizer: Normalizer<ResourceT>,
    filterDefault?: FilterType
}

export default function ListingController<ResourceT extends Identifiable>({
    renderListing,
    renderFilter,
    renderPagination,
    title,
    datasource,
    normalizer,
    filterDefault,
}: ListingControllerProps<ResourceT>) {

    const matches = useMatches();

    const lastMatch = matches.at(-1);

    const [resources, setResources] = useState<Array<ResourceT>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [filters, setFilters] = useState<FilterType>(filterDefault || {});

    const [currentPage, setCurrentPage] = React.useState(1);
    const [resourcesPerPage, setResourcesPerPage] = React.useState(30);
    // const lastResource = currentPage * resourcesPerPage;
    // const firstResource = lastResource - resourcesPerPage;

    const {index} = useDatasource<ResourceT>(datasource, normalizer);

    const paginate = ({selected}: any) => {
        setCurrentPage(selected + 1);
        setFilters((prevState) => {
            return {
                ...prevState,
                page: selected + 1,
            };
        });
    };

    const changeItemsPerPage = (choice: number) => {
        setResourcesPerPage(choice);
    };

    const onFilter = (data: any) => {
        const newFilters = Object.keys(data)
            .filter((key: keyof typeof data) => typeof data[key] === 'boolean'
                ? true
                : Boolean(data[key]))
            .reduce((acc, key) => ({...acc, ...{[key]: data[key]}}), {});

        setFilters({...Object.assign({}, filterDefault || {}, newFilters)});
        localStorage[lastMatch?.id + 'Filter'] = JSON.stringify(newFilters);
    };

    const onReset = () => {
        setFilters(filterDefault || {});
        setCurrentPage(1);
        localStorage[lastMatch?.id + 'Filter'] = JSON.stringify({});
    };

    const reloadOne = (resource: ResourceT) => {
        setResources((prevState) => {
            const newState = [...prevState];

            newState[newState.findIndex(el => el.id ===
                resource.id)] = resource;
            return newState;
        });
    };

    useEffect(() => {
        const localStoredFilters = JSON.parse(localStorage[lastMatch?.id +
        'Filter'] ?? '{}');
        setFilters({
            ...Object.assign(
                {},
                filterDefault || {},
                localStoredFilters,
            ),
        });
    }, [lastMatch, filterDefault]);

    useEffect(() => {
        setIsLoading(true);

        const controller = new AbortController();

        index(
            filters, undefined, controller,
        ).then((result: any) => {
            setIsLoading(false);
            setResources(result);
        }).catch((err: any) => {
            if (axios.isCancel(err)) {
                return;
            }
            throw err;
        });

        return () => {
            controller.abort();
        };
    }, [index, filters]);

    const currentRessources = resources;

    const filter = renderFilter ? renderFilter({
        onFilter,
        onReset,
        defaultValues: filters,
    }) : null;
    const listing = renderListing({
        onFilter,
        resources: currentRessources,
        isLoading,
        filters,
        reloadOne,
    });
    const pagination = renderPagination ? renderPagination({
        resources,
        paginate,
        resourcesPerPage,
        changeItemsPerPage,
        currentPage,
    }) : null;

    return <>
        {title}
        {filter}
        {listing}
        {pagination}
    </>;
}
