import {createContext, ReactNode, useState} from "react";

export type FlashType = {
    type: "success" | "warning" | "danger"
    body: string
}

export interface FlashContextInterface {
    flashes: FlashType[],
    addSuccess: (body: string) => void,
    addError: (body: string) => void,
    removeFlash: () => void,
}

const initialState: FlashContextInterface = {
    flashes: [],
    addSuccess: () => {
    },
    addError: () => {
    },
    removeFlash: () => {
    },
};

export const FlashContext = createContext<FlashContextInterface>(initialState);

export const FlashContextProvider = ({children}: { children: ReactNode }) => {

    const [state, setState] = useState<FlashContextInterface>(initialState);

    const value: FlashContextInterface = {
        flashes: state.flashes,
        addSuccess: (body: string) => {
            setState({
                ...state,
                flashes: [...state.flashes, {type: "success", body: body}],
            })
            setTimeout(value.removeFlash, 5000)
        },
        addError: (body: string) => {
            setState({
                ...state,
                flashes: [...state.flashes, {type: "danger", body: body}],
            })
        },
        removeFlash: () => {
            setState({
                ...state,
                flashes: [],
            })
        },
    };

    return <FlashContext.Provider value={value} children={children}/>
}