import {renderFormProps} from '../../../FormController';
import {
    AccessProfilePermissionsTable,
} from '../../../../components/app/App/AccessProfilePermissionsTable';
import React from 'react';
import CollapsiblePanel from '../../../../components/CollapsiblePanel';
import {MyrudderRoles} from '../../../../model/enum/roles/Myrudder';
import {ControlSwitchInput} from '../../../../components/SwitchButton';
import {GroupMyrudder} from '../../../../model/GroupMyrudder';

interface AccessProfilePermissionsProps extends renderFormProps<GroupMyrudder> {
    resource: GroupMyrudder;
}

export const MyrudderPermissions = ({
    register,
    control,
    resource,
    setValue,
}: AccessProfilePermissionsProps) => {

    return (
        <>
            <div>
                <ControlSwitchInput
                    control={control}
                    name={'client'}
                    classes={'form-switch-md mb-1'}
                    register={register}
                    value={resource.client}
                    label={'Client ?'}
                />
            </div>
            <CollapsiblePanel
                accordionHeaderClasses={'bg-light'}
                accordionItems={
                    [
                        {
                            eventKey: '0',
                            header: 'Menu',
                            children:
                                <AccessProfilePermissionsTable
                                    id={'myrudder_group_menu'}
                                    register={register}
                                    setValue={setValue}
                                    resource={resource}
                                    modules={[
                                        {
                                            label: 'Accueil',
                                            icon: 'fas fa-address-book',
                                            permissions: [
                                                {
                                                    rights: [
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_ADRESSE_SHOW,
                                                            value: MyrudderRoles.ROLE_PROJECT_ADRESSE_SHOW,
                                                            active: true,
                                                        },
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_ADRESSE_CREATE,
                                                            value: MyrudderRoles.ROLE_PROJECT_ADRESSE_CREATE,
                                                            active: true,
                                                        },
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_ADRESSE_EDIT,
                                                            value: MyrudderRoles.ROLE_PROJECT_ADRESSE_EDIT,
                                                            active: true,
                                                        },
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_ADRESSE_DELETE,
                                                            value: MyrudderRoles.ROLE_PROJECT_ADRESSE_DELETE,
                                                            active: true,
                                                        },
                                                    ],
                                                    label: 'Carnet d\'adresses',
                                                },
                                            ],
                                        },
                                        {
                                            label: 'Morning Check',
                                            icon: 'fas fa-chart-bar',
                                            permissions: [
                                                {
                                                    rights: [
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_MORNING_CHECK_SHOW,
                                                            value: MyrudderRoles.ROLE_PROJECT_MORNING_CHECK_SHOW,
                                                            active: true,
                                                        },
                                                    ], label: 'Permission de base ',
                                                },
                                            ],
                                        },
                                        {
                                            label: 'Chat',
                                            icon: 'fas fa-comments',
                                            permissions: [
                                                {
                                                    rights: [
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_CHAT_SHOW,
                                                            value: MyrudderRoles.ROLE_PROJECT_CHAT_SHOW,
                                                            active: true,
                                                        },
                                                    ], label: 'Chat',
                                                },
                                            ],
                                        },
                                        {
                                            label: 'MyShipment',
                                            icon: 'fas fa-rocket',
                                            permissions: [
                                                {
                                                    rights: [
                                                        {
                                                            name: '',
                                                            value: undefined,
                                                            active: false,
                                                        },
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_MY_SHIPMENT_NOUVELLE_COMMANDE_CREATE,
                                                            value: MyrudderRoles.ROLE_PROJECT_MY_SHIPMENT_NOUVELLE_COMMANDE_CREATE,
                                                            active: true,
                                                        },
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_MY_SHIPMENT_NOUVELLE_COMMANDE_EDIT,
                                                            value: MyrudderRoles.ROLE_PROJECT_MY_SHIPMENT_NOUVELLE_COMMANDE_EDIT,
                                                            active: true,
                                                        },
                                                        {
                                                            name: '',
                                                            value: undefined,
                                                            active: false,
                                                        },
                                                    ],
                                                    label: 'Nouvelle commande',
                                                },
                                                {
                                                    rights: [
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_MY_SHIPMENT_TRANSPORT_EN_COURS_SHOW,
                                                            value: MyrudderRoles.ROLE_PROJECT_MY_SHIPMENT_TRANSPORT_EN_COURS_SHOW,
                                                            active: true,
                                                        },
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_MY_SHIPMENT_TRANSPORT_EN_COURS_CREATE,
                                                            value: MyrudderRoles.ROLE_PROJECT_MY_SHIPMENT_TRANSPORT_EN_COURS_CREATE,
                                                            active: true,
                                                        },
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_MY_SHIPMENT_TRANSPORT_EN_COURS_EDIT,
                                                            value: MyrudderRoles.ROLE_PROJECT_MY_SHIPMENT_TRANSPORT_EN_COURS_EDIT,
                                                            active: true,
                                                        },
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_MY_SHIPMENT_TRANSPORT_EN_COURS_DELETE,
                                                            value: MyrudderRoles.ROLE_PROJECT_MY_SHIPMENT_TRANSPORT_EN_COURS_DELETE,
                                                            active: true,
                                                        },
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_MY_SHIPMENT_TRANSPORT_EN_COURS_CLASSIC_VIEW,
                                                            value: MyrudderRoles.ROLE_PROJECT_MY_SHIPMENT_TRANSPORT_EN_COURS_CLASSIC_VIEW,
                                                            active: true,
                                                            label: 'Vue tableau',
                                                        },
                                                    ],
                                                    label: 'Transport en cours',
                                                },
                                                {
                                                    rights: [
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_MY_SHIPMENT_WORLD_MAP_SHOW,
                                                            value: MyrudderRoles.ROLE_PROJECT_MY_SHIPMENT_WORLD_MAP_SHOW,
                                                            active: true,
                                                        },
                                                    ], label: 'World map',
                                                },
                                                {
                                                    rights: [
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_MY_SHIPMENT_HISTORIQUE_SHOW,
                                                            value: MyrudderRoles.ROLE_PROJECT_MY_SHIPMENT_HISTORIQUE_SHOW,
                                                            active: true,
                                                        },
                                                        {
                                                            name: '',
                                                            value: undefined,
                                                            active: false,
                                                        },
                                                        {
                                                            name: '',
                                                            value: undefined,
                                                            active: false,
                                                        },
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_MY_SHIPMENT_HISTORIQUE_DELETE,
                                                            value: MyrudderRoles.ROLE_PROJECT_MY_SHIPMENT_HISTORIQUE_DELETE,
                                                            active: true,
                                                        },
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_MY_SHIPMENT_HISTORIQUE_CLASSIC_VIEW,
                                                            value: MyrudderRoles.ROLE_PROJECT_MY_SHIPMENT_HISTORIQUE_CLASSIC_VIEW,
                                                            active: true,
                                                            label: 'Vue tableau',
                                                        },
                                                    ], label: 'Historique',
                                                },
                                                {
                                                    rights: [
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_MY_SHIPMENT_CLIENTS_SHOW,
                                                            value: MyrudderRoles.ROLE_PROJECT_MY_SHIPMENT_CLIENTS_SHOW,
                                                            active: true,
                                                        },
                                                    ],
                                                    label: 'Commandes clients',
                                                },{
                                                    rights: [
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_MY_SHIPMENT_IMPACT_COCKPIT_SHOW,
                                                            value: MyrudderRoles.ROLE_PROJECT_MY_SHIPMENT_IMPACT_COCKPIT_SHOW,
                                                            active: true,
                                                        },
                                                    ],
                                                    label: 'Bilan carbone - cockpit',
                                                },{
                                                    rights: [
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_MY_SHIPMENT_IMPACT_SHOW,
                                                            value: MyrudderRoles.ROLE_PROJECT_MY_SHIPMENT_IMPACT_SHOW,
                                                            active: true,
                                                        },
                                                    ],
                                                    label: 'Bilan carbone - impact',
                                                },
                                            ],
                                        },
                                        {
                                            label: 'MyTrucks',
                                            icon: 'fas fa-truck',
                                            permissions: [
                                                {
                                                    rights: [
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_COMMANDE_SHOW,
                                                            value: MyrudderRoles.ROLE_PROJECT_COMMANDE_SHOW,
                                                            active: true,
                                                        },
                                                    ], label: 'Tableau de bord',
                                                },
                                                {
                                                    rights: [
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_PLANNING_SHOW,
                                                            value: MyrudderRoles.ROLE_PROJECT_PLANNING_SHOW,
                                                            active: true,
                                                        },
                                                    ], label: 'Planning',
                                                },
                                                {
                                                    rights: [
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_COMMANDE_SHOW,
                                                            value: MyrudderRoles.ROLE_PROJECT_COMMANDE_SHOW,
                                                            active: true,
                                                        },
                                                    ], label: 'Vos commandes',
                                                },
                                            ],
                                        },
                                        {
                                            label: 'MyProof',
                                            icon: 'fas fa-receipt',
                                            permissions: [
                                                {
                                                    rights: [
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_MY_PROOF_MES_CONTROLES_SHOW,
                                                            value: MyrudderRoles.ROLE_PROJECT_MY_PROOF_MES_CONTROLES_SHOW,
                                                            active: true,
                                                        },
                                                        {
                                                            name: '',
                                                            value: undefined,
                                                            active: false,
                                                        },
                                                        {
                                                            name: '',
                                                            value: undefined,
                                                            active: false,
                                                        },
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_MY_PROOF_MES_CONTROLES_DELETE,
                                                            value: MyrudderRoles.ROLE_PROJECT_MY_PROOF_MES_CONTROLES_DELETE,
                                                            active: true,
                                                        },
                                                    ], label: ' Mes contrôles',
                                                },
                                                {
                                                    rights: [
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_MY_PROOF_MES_CONTENEURS_SHOW,
                                                            value: MyrudderRoles.ROLE_PROJECT_MY_PROOF_MES_CONTENEURS_SHOW,
                                                            active: true,
                                                        },
                                                        {
                                                            name: '',
                                                            value: undefined,
                                                            active: false,
                                                        },
                                                        {
                                                            name: '',
                                                            value: undefined,
                                                            active: false,
                                                        },
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_MY_PROOF_MES_CONTENEURS_DELETE,
                                                            value: MyrudderRoles.ROLE_PROJECT_MY_PROOF_MES_CONTENEURS_DELETE,
                                                            active: true,
                                                        },
                                                    ], label: 'Mes conteneurs',
                                                },
                                                {
                                                    rights: [
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_MY_PROOF_MES_TRANSPORTS_SHOW,
                                                            value: MyrudderRoles.ROLE_PROJECT_MY_PROOF_MES_TRANSPORTS_SHOW,
                                                            active: true,
                                                        },
                                                        {
                                                            name: '',
                                                            value: undefined,
                                                            active: false,
                                                        },
                                                        {
                                                            name: '',
                                                            value: undefined,
                                                            active: false,
                                                        },
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_MY_PROOF_MES_TRANSPORTS_DELETE,
                                                            value: MyrudderRoles.ROLE_PROJECT_MY_PROOF_MES_TRANSPORTS_DELETE,
                                                            active: true,
                                                        },
                                                    ], label: 'Mes transports',
                                                },
                                                {
                                                    rights: [
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_MY_PROOF_TOUTES_SHOW,
                                                            value: MyrudderRoles.ROLE_PROJECT_MY_PROOF_TOUTES_SHOW,
                                                            active: true,
                                                        },
                                                        {
                                                            name: '',
                                                            value: undefined,
                                                            active: false,
                                                        },
                                                        {
                                                            name: '',
                                                            value: undefined,
                                                            active: false,
                                                        },
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_MY_PROOF_TOUTES_DELETE,
                                                            value: MyrudderRoles.ROLE_PROJECT_MY_PROOF_TOUTES_DELETE,
                                                            active: true,
                                                        },
                                                    ],
                                                    label: ' Toutes mes photos',
                                                },
                                                {
                                                    rights: [
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_MY_PROOF_AUTHENTIFICATION_SHOW,
                                                            value: MyrudderRoles.ROLE_PROJECT_MY_PROOF_AUTHENTIFICATION_SHOW,
                                                            active: true,
                                                        },
                                                    ],
                                                    label: 'Authentification des photos',
                                                },
                                            ],
                                        },
                                        {
                                            label: 'MyInvoice',
                                            icon: 'fas fa-file-invoice',
                                            permissions: [
                                                {
                                                    rights: [
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_FACTURES_A_TRAITER_SHOW,
                                                            value: MyrudderRoles.ROLE_PROJECT_FACTURES_A_TRAITER_SHOW,
                                                            active: true,
                                                        },
                                                        {
                                                            name: '',
                                                            value: undefined,
                                                            active: false,
                                                        },
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_FACTURES_A_TRAITER_EDIT,
                                                            value: MyrudderRoles.ROLE_PROJECT_FACTURES_A_TRAITER_EDIT,
                                                            active: true,
                                                        },
                                                    ],
                                                    label: 'Factures à régler',
                                                },
                                                {
                                                    rights: [
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_FACTURES_VALIDES_SHOW,
                                                            value: MyrudderRoles.ROLE_PROJECT_FACTURES_VALIDES_SHOW,
                                                            active: true,
                                                        },
                                                        {
                                                            name: '',
                                                            value: undefined,
                                                            active: false,
                                                        },
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_FACTURES_VALIDES_EDIT,
                                                            value: MyrudderRoles.ROLE_PROJECT_FACTURES_VALIDES_EDIT,
                                                            active: true,
                                                        },
                                                    ],
                                                    label: 'Factures validées',
                                                },
                                                {
                                                    rights: [
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_FACTURES_LITIGES_SHOW,
                                                            value: MyrudderRoles.ROLE_PROJECT_FACTURES_LITIGES_SHOW,
                                                            active: true,
                                                        },
                                                        {
                                                            name: '',
                                                            value: undefined,
                                                            active: false,
                                                        },
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_FACTURES_LITIGES_EDIT,
                                                            value: MyrudderRoles.ROLE_PROJECT_FACTURES_LITIGES_EDIT,
                                                            active: true,
                                                        },
                                                    ],
                                                    label: 'Factures en litiges',
                                                },
                                                {
                                                    rights: [
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_MES_FACTURES_SHOW,
                                                            value: MyrudderRoles.ROLE_PROJECT_MES_FACTURES_SHOW,
                                                            active: true,
                                                        },
                                                    ], label: 'Historique',
                                                },
                                                {
                                                    rights: [
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_RELANCE_FACTURES_SHOW,
                                                            value: MyrudderRoles.ROLE_PROJECT_RELANCE_FACTURES_SHOW,
                                                            active: true,
                                                        },
                                                        {
                                                            name: '',
                                                            value: undefined,
                                                            active: false,
                                                        },
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_RELANCE_FACTURES_EDIT,
                                                            value: MyrudderRoles.ROLE_PROJECT_RELANCE_FACTURES_EDIT,
                                                            active: true,
                                                        },
                                                    ],
                                                    label: 'Relances Factures',
                                                },
                                                {
                                                    rights: [
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_BATCH_RELANCE_FACTURES_SHOW,
                                                            value: MyrudderRoles.ROLE_PROJECT_BATCH_RELANCE_FACTURES_SHOW,
                                                            active: true,
                                                        },
                                                    ],
                                                    label: 'Batch - Relances',
                                                },
                                            ],
                                        },
                                        {
                                            label: 'MyKPI',
                                            icon: 'fas fa-file-invoice',
                                            permissions: [
                                                {
                                                    rights: [
                                                        {
                                                            name: MyrudderRoles.ROLE_PROJECT_CARBON_IMPACT_SHOW,
                                                            value: MyrudderRoles.ROLE_PROJECT_CARBON_IMPACT_SHOW,
                                                            active: true,
                                                        },
                                                        {
                                                            name: '',
                                                            value: undefined,
                                                            active: false,
                                                        },
                                                        {
                                                            name: '',
                                                            value: undefined,
                                                            active: false,
                                                        },
                                                    ],
                                                    label: 'Impact CO2',
                                                },
                                            ],
                                        },
                                        {
                                            label: 'API',
                                            icon: 'fas fa-globe',
                                            permissions: [
                                                {
                                                    rights: [
                                                        {
                                                            name: MyrudderRoles.ROLE_API_SHOW,
                                                            value: MyrudderRoles.ROLE_API_SHOW,
                                                            active: true,
                                                        },
                                                    ],
                                                    label: 'Permission de base ',
                                                },
                                            ],
                                        },

                                    ]}

                                />,
                        },
                        {
                            eventKey: '1',
                            header: 'Header',
                            children: <AccessProfilePermissionsTable
                                id={'myrudder_group_header'}
                                setValue={setValue}
                                resource={resource}
                                register={register}
                                modules={[
                                    {
                                        label: '',
                                        icon: 'fas fa-cogs',
                                        permissions: [
                                            {
                                                rights: [
                                                    {
                                                        name: MyrudderRoles.ROLE_PROJECT_AUTHENTIFICATION_PHOTO_SHOW,
                                                        value: MyrudderRoles.ROLE_PROJECT_AUTHENTIFICATION_PHOTO_SHOW,
                                                        active: true,
                                                    },
                                                    {
                                                        name: MyrudderRoles.ROLE_PROJECT_AUTHENTIFICATION_PHOTO_CREATE,
                                                        value: MyrudderRoles.ROLE_PROJECT_AUTHENTIFICATION_PHOTO_CREATE,
                                                        active: true,
                                                    },
                                                    {
                                                        name: MyrudderRoles.ROLE_PROJECT_AUTHENTIFICATION_PHOTO_EDIT,
                                                        value: MyrudderRoles.ROLE_PROJECT_AUTHENTIFICATION_PHOTO_EDIT,
                                                        active: true,
                                                    },
                                                    {
                                                        name: MyrudderRoles.ROLE_PROJECT_AUTHENTIFICATION_PHOTO_DELETE,
                                                        value: MyrudderRoles.ROLE_PROJECT_AUTHENTIFICATION_PHOTO_DELETE,
                                                        active: true,
                                                    },
                                                ],
                                                label: 'Authentification des photos',
                                            },

                                            {
                                                rights: [
                                                    {
                                                        name: MyrudderRoles.ROLE_PROJECT_CLIENT_SHOW,
                                                        value: MyrudderRoles.ROLE_PROJECT_CLIENT_SHOW,
                                                        active: true,
                                                    },
                                                    {
                                                        name: '',
                                                        value: undefined,
                                                        active: false,
                                                    },
                                                    {
                                                        name: MyrudderRoles.ROLE_PROJECT_CLIENT_EDIT,
                                                        value: MyrudderRoles.ROLE_PROJECT_CLIENT_EDIT,
                                                        active: true,
                                                    },
                                                ], label: 'Clients',
                                            },
                                            {
                                                rights: [
                                                    {
                                                        name: MyrudderRoles.ROLE_PROJECT_CGU_SHOW,
                                                        value: MyrudderRoles.ROLE_PROJECT_CGU_SHOW,
                                                        active: true,
                                                    },
                                                    {
                                                        name: MyrudderRoles.ROLE_PROJECT_CGU_CREATE,
                                                        value: MyrudderRoles.ROLE_PROJECT_CGU_CREATE,
                                                        active: true,
                                                    },
                                                    {
                                                        name: MyrudderRoles.ROLE_PROJECT_CGU_EDIT,
                                                        value: MyrudderRoles.ROLE_PROJECT_CGU_EDIT,
                                                        active: true,
                                                    },
                                                    {
                                                        name: MyrudderRoles.ROLE_PROJECT_CGU_DELETE,
                                                        value: MyrudderRoles.ROLE_PROJECT_CGU_DELETE,
                                                        active: true,
                                                    },
                                                ],
                                                label: 'Conditions Générales d\'Utilisation',
                                            },
                                            {
                                                rights: [
                                                    {
                                                        name: MyrudderRoles.ROLE_PROJECT_CONTACT_SHOW,
                                                        value: MyrudderRoles.ROLE_PROJECT_CONTACT_SHOW,
                                                        active: true,
                                                    },
                                                    {
                                                        name: MyrudderRoles.ROLE_PROJECT_CONTACT_CREATE,
                                                        value: MyrudderRoles.ROLE_PROJECT_CONTACT_CREATE,
                                                        active: true,
                                                    },
                                                    {
                                                        name: MyrudderRoles.ROLE_PROJECT_CONTACT_EDIT,
                                                        value: MyrudderRoles.ROLE_PROJECT_CONTACT_EDIT,
                                                        active: true,
                                                    },
                                                    {
                                                        name: MyrudderRoles.ROLE_PROJECT_CONTACT_DELETE,
                                                        value: MyrudderRoles.ROLE_PROJECT_CONTACT_DELETE,
                                                        active: true,
                                                    },
                                                ], label: 'Contacts',
                                            },
                                        ],
                                    },

                                ]}/>,
                        },
                        {
                            eventKey: '2',
                            header: 'Autres',
                            children: <AccessProfilePermissionsTable
                                id={'myrudder_group_autres'}
                                setValue={setValue}
                                resource={resource}
                                register={register}
                                modules={[
                                    {
                                        label: 'API',
                                        icon: 'fas fa-globe',
                                        permissions: [
                                            {
                                                rights: [
                                                    {
                                                        name: MyrudderRoles.ROLE_PROJECT_SHIPMENT_SUBSCRIPTION_SHOW,
                                                        value: MyrudderRoles.ROLE_PROJECT_SHIPMENT_SUBSCRIPTION_SHOW,
                                                        active: true,
                                                    },
                                                    {
                                                        name: MyrudderRoles.ROLE_PROJECT_SHIPMENT_SUBSCRIPTION_CREATE,
                                                        value: MyrudderRoles.ROLE_PROJECT_SHIPMENT_SUBSCRIPTION_CREATE,
                                                        active: true,
                                                    },
                                                    {
                                                        name: '',
                                                        value: undefined,
                                                        active: false,
                                                    },
                                                    {
                                                        name: MyrudderRoles.ROLE_PROJECT_SHIPMENT_SUBSCRIPTION_DELETE,
                                                        value: MyrudderRoles.ROLE_PROJECT_SHIPMENT_SUBSCRIPTION_DELETE,
                                                        active: true,
                                                    },
                                                ],
                                                label: 'ShipmentSubscription',
                                            },
                                            {
                                                rights: [
                                                    {
                                                        name: MyrudderRoles.ROLE_SHIPMENT_GEOTRACK_READ,
                                                        value: MyrudderRoles.ROLE_SHIPMENT_GEOTRACK_READ,
                                                        active: true,
                                                    },
                                                    {
                                                        name: MyrudderRoles.ROLE_SHIPMENT_GEOTRACK_EDIT,
                                                        value: MyrudderRoles.ROLE_SHIPMENT_GEOTRACK_EDIT,
                                                        active: true,
                                                    }
                                                ],
                                                label: 'Geotracking',
                                            }
                                        ],
                                    },
                                    {
                                        label: 'MyInvoice - fonctions supplémentaires',
                                        icon: 'fas fa-file-invoice',
                                        permissions: [
                                            {
                                                rights: [
                                                    {
                                                        name: MyrudderRoles.ROLE_PROJECT_MES_FACTURES_DELETE_COMMENTAIRE_SHOW,
                                                        value: MyrudderRoles.ROLE_PROJECT_MES_FACTURES_DELETE_COMMENTAIRE_SHOW,
                                                        active: true,
                                                    },
                                                ],
                                                label: 'Suppression commentaire',
                                            },
                                            {
                                                rights: [
                                                    {
                                                        name: MyrudderRoles.ROLE_PROJECT_MES_FACTURES_DELETE_PIECE_JOINTE_SHOW,
                                                        value: MyrudderRoles.ROLE_PROJECT_MES_FACTURES_DELETE_PIECE_JOINTE_SHOW,
                                                        active: true,
                                                    },
                                                ],
                                                label: 'Suppression pièce jointe',
                                            },
                                        ],
                                    },

                                ]}/>,
                        },
                    ]
                }
            />
        </>
    );
};