import {Identifiable} from './interface/Identifiable';
import {MyrudderRoles} from './enum/roles/Myrudder';

export class GroupMyrudder implements Identifiable {

    public client: boolean = false;
    public roles: Array<MyrudderRoles | string> = [];
    public group: string | null = null;

    constructor(
        readonly id: number = 0,
    ) {
    }
}
