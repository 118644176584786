import React from 'react';
import {useForm, FieldValues} from 'react-hook-form';
import EmailInput from "../../fields/EmailInput";
import PasswordInput from "../../fields/PasswordInput";
import CheckboxInput from "../../fields/CheckboxInput";
import {Alert} from "react-bootstrap";
// import TextInput from "../../fields/TextInput";
import ButtonComponent from "../../../components/ButtonComponent";

interface RegisterFormProps {
    formClass?: string,
    id?: string,
    formName?: string
}

const RegisterForm = ({formClass, id, formName}: RegisterFormProps) => {
    const [error] = React.useState()

    const methods = useForm({});
    const {
        handleSubmit,
        register,
        control
    } = methods;

    const signup = (FormData: FieldValues) => {
        console.log(FormData);
    };

    return (
        <>
            {error ? (
                <Alert variant="danger" className="my-2">
                    {error}
                </Alert>
            ) : null}

            <form id={id} name={formName} onSubmit={handleSubmit((data) => {signup(data)})} className={formClass} noValidate>
                {/*<TextInput*/}
                {/*    name={'full_name'}*/}
                {/*    containerClass={'form-group mb-3'}*/}
                {/*    register={register}*/}
                {/*    placeholder={"Nom"}*/}
                {/*    control={control}*/}
                {/*    defaultValue={''}*/}
                {/*/>*/}

                <EmailInput
                    name={"username"}
                    containerClass={'form-group mb-3'}
                    register={register}
                    placeholder={"Email"}
                />

                <PasswordInput
                    name={"password"}
                    containerClass={'form-group mb-3'}
                    register={register}
                    placeholder={"Mot de passe"}
                    control={control}
                />

                <CheckboxInput
                    name={"cgu_accept"}
                    label={"J'accepte les conditions générales d'utilisation"}
                    containerClass={"form-group mb-3"}
                    register={register}
                />

                <div className="d-grid text-center">
                    <ButtonComponent
                        buttonVariant={"primary"}
                        buttonLabel={"Inscription"}
                        buttonType={"submit"}
                        classes={"btn waves-effect waves-light btn-lg btn-block mt-2"}
                    />
                </div>

            </form>
        </>
    );
};

export default RegisterForm;