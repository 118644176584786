import {normalizedType, Normalizer} from './normalizer';
import {GroupWorkflow} from '../../model/GroupWorkflow';

const GroupWorkflowNormalizer = new class implements Normalizer<GroupWorkflow> {

    normalize(data: normalizedType<GroupWorkflow>): GroupWorkflow {
        const resource = new GroupWorkflow(
            data.id,
        );

        resource.group = data.group;
        resource.roles = data.roles;

        return resource;
    }

}();

export {GroupWorkflowNormalizer};