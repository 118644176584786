export enum MyrudderRoles {
    ROLE_PROJECT_COMMANDE_SHOW = 'ROLE_PROJECT_COMMANDE_SHOW',
    ROLE_PROJECT_PLANNING_SHOW = 'ROLE_PROJECT_PLANNING_SHOW',
    ROLE_PROJECT_MES_FACTURES_SHOW = 'ROLE_PROJECT_MES_FACTURES_SHOW',
    ROLE_PROJECT_MES_FACTURES_DELETE_COMMENTAIRE_SHOW = 'ROLE_PROJECT_MES_FACTURES_DELETE_COMMENTAIRE_SHOW',
    ROLE_PROJECT_MES_FACTURES_DELETE_PIECE_JOINTE_SHOW = 'ROLE_PROJECT_MES_FACTURES_DELETE_PIECE_JOINTE_SHOW',
    ROLE_PROJECT_FACTURES_A_TRAITER_SHOW = 'ROLE_PROJECT_FACTURES_A_TRAITER_SHOW',
    ROLE_PROJECT_FACTURES_A_TRAITER_EDIT = 'ROLE_PROJECT_FACTURES_A_TRAITER_EDIT',
    ROLE_PROJECT_RELANCE_FACTURES_SHOW = 'ROLE_PROJECT_RELANCE_FACTURES_SHOW',
    ROLE_PROJECT_RELANCE_FACTURES_EDIT = 'ROLE_PROJECT_RELANCE_FACTURES_EDIT',
    ROLE_PROJECT_BATCH_RELANCE_FACTURES_SHOW = 'ROLE_PROJECT_BATCH_RELANCE_FACTURES_SHOW',
    ROLE_PROJECT_FACTURES_VALIDES_SHOW = 'ROLE_PROJECT_FACTURES_VALIDES_SHOW',
    ROLE_PROJECT_FACTURES_VALIDES_EDIT = 'ROLE_PROJECT_FACTURES_VALIDES_EDIT',
    ROLE_PROJECT_FACTURES_LITIGES_SHOW = 'ROLE_PROJECT_FACTURES_LITIGES_SHOW',
    ROLE_PROJECT_FACTURES_LITIGES_EDIT = 'ROLE_PROJECT_FACTURES_LITIGES_EDIT',
    ROLE_PROJECT_CARBON_IMPACT_SHOW = 'ROLE_PROJECT_CARBON_IMPACT_SHOW',
    ROLE_PROJECT_CONTACT_SHOW = 'ROLE_PROJECT_CONTACT_SHOW',
    ROLE_PROJECT_CONTACT_CREATE = 'ROLE_PROJECT_CONTACT_CREATE',
    ROLE_PROJECT_CONTACT_EDIT = 'ROLE_PROJECT_CONTACT_EDIT',
    ROLE_PROJECT_CONTACT_DELETE = 'ROLE_PROJECT_CONTACT_DELETE',
    ROLE_PROJECT_CLIENT_SHOW = 'ROLE_PROJECT_CLIENT_SHOW',
    ROLE_PROJECT_CLIENT_EDIT = 'ROLE_PROJECT_CLIENT_EDIT',
    ROLE_PROJECT_CGU_SHOW = 'ROLE_PROJECT_CGU_SHOW',
    ROLE_PROJECT_CGU_CREATE = 'ROLE_PROJECT_CGU_CREATE',
    ROLE_PROJECT_CGU_EDIT = 'ROLE_PROJECT_CGU_EDIT',
    ROLE_PROJECT_CGU_DELETE = 'ROLE_PROJECT_CGU_DELETE',
    ROLE_PROJECT_MY_PROOF_MES_CONTROLES_SHOW = 'ROLE_PROJECT_MY_PROOF_MES_CONTROLES_SHOW',
    ROLE_PROJECT_MY_PROOF_MES_CONTROLES_DELETE = 'ROLE_PROJECT_MY_PROOF_MES_CONTROLES_DELETE',
    ROLE_PROJECT_MY_PROOF_MES_CONTENEURS_SHOW = 'ROLE_PROJECT_MY_PROOF_MES_CONTENEURS_SHOW',
    ROLE_PROJECT_MY_PROOF_MES_CONTENEURS_DELETE = 'ROLE_PROJECT_MY_PROOF_MES_CONTENEURS_DELETE',
    ROLE_PROJECT_MY_PROOF_MES_TRANSPORTS_SHOW = 'ROLE_PROJECT_MY_PROOF_MES_TRANSPORTS_SHOW',
    ROLE_PROJECT_MY_PROOF_MES_TRANSPORTS_DELETE = 'ROLE_PROJECT_MY_PROOF_MES_TRANSPORTS_DELETE',
    ROLE_PROJECT_MY_PROOF_TOUTES_SHOW = 'ROLE_PROJECT_MY_PROOF_TOUTES_SHOW',
    ROLE_PROJECT_MY_PROOF_TOUTES_DELETE = 'ROLE_PROJECT_MY_PROOF_TOUTES_DELETE',
    ROLE_PROJECT_MY_PROOF_AUTHENTIFICATION_SHOW = 'ROLE_PROJECT_MY_PROOF_AUTHENTIFICATION_SHOW',
    ROLE_PROJECT_AUTHENTIFICATION_PHOTO_SHOW = 'ROLE_PROJECT_AUTHENTIFICATION_PHOTO_SHOW',
    ROLE_PROJECT_AUTHENTIFICATION_PHOTO_CREATE = 'ROLE_PROJECT_AUTHENTIFICATION_PHOTO_CREATE',
    ROLE_PROJECT_AUTHENTIFICATION_PHOTO_EDIT = 'ROLE_PROJECT_AUTHENTIFICATION_PHOTO_EDIT',
    ROLE_PROJECT_AUTHENTIFICATION_PHOTO_DELETE = 'ROLE_PROJECT_AUTHENTIFICATION_PHOTO_DELETE',
    ROLE_PROJECT_ADRESSE_SHOW = 'ROLE_PROJECT_ADRESSE_SHOW',
    ROLE_PROJECT_ADRESSE_CREATE = 'ROLE_PROJECT_ADRESSE_CREATE',
    ROLE_PROJECT_ADRESSE_EDIT = 'ROLE_PROJECT_ADRESSE_EDIT',
    ROLE_PROJECT_ADRESSE_DELETE = 'ROLE_PROJECT_ADRESSE_DELETE',
    ROLE_PROJECT_MY_SHIPMENT_NOUVELLE_COMMANDE_CREATE = 'ROLE_PROJECT_MY_SHIPMENT_NOUVELLE_COMMANDE_CREATE',
    ROLE_PROJECT_MY_SHIPMENT_NOUVELLE_COMMANDE_EDIT = 'ROLE_PROJECT_MY_SHIPMENT_NOUVELLE_COMMANDE_EDIT',
    ROLE_PROJECT_MY_SHIPMENT_TRANSPORT_EN_COURS_SHOW = 'ROLE_PROJECT_MY_SHIPMENT_TRANSPORT_EN_COURS_SHOW',
    ROLE_PROJECT_MY_SHIPMENT_TRANSPORT_EN_COURS_CREATE = 'ROLE_PROJECT_MY_SHIPMENT_TRANSPORT_EN_COURS_CREATE',
    ROLE_PROJECT_MY_SHIPMENT_TRANSPORT_EN_COURS_EDIT = 'ROLE_PROJECT_MY_SHIPMENT_TRANSPORT_EN_COURS_EDIT',
    ROLE_PROJECT_MY_SHIPMENT_TRANSPORT_EN_COURS_DELETE = 'ROLE_PROJECT_MY_SHIPMENT_TRANSPORT_EN_COURS_DELETE',
    ROLE_PROJECT_MY_SHIPMENT_TRANSPORT_EN_COURS_CLASSIC_VIEW = 'ROLE_PROJECT_MY_SHIPMENT_TRANSPORT_EN_COURS_CLASSIC_VIEW',
    ROLE_PROJECT_MY_SHIPMENT_HISTORIQUE_SHOW = 'ROLE_PROJECT_MY_SHIPMENT_HISTORIQUE_SHOW',
    ROLE_PROJECT_MY_SHIPMENT_HISTORIQUE_DELETE = 'ROLE_PROJECT_MY_SHIPMENT_HISTORIQUE_DELETE',
    ROLE_PROJECT_MY_SHIPMENT_HISTORIQUE_CLASSIC_VIEW = 'ROLE_PROJECT_MY_SHIPMENT_HISTORIQUE_CLASSIC_VIEW',
    ROLE_PROJECT_MY_SHIPMENT_IMPACT_SHOW = 'ROLE_PROJECT_MY_SHIPMENT_IMPACT_SHOW',
    ROLE_PROJECT_MY_SHIPMENT_IMPACT_COCKPIT_SHOW = 'ROLE_PROJECT_MY_SHIPMENT_IMPACT_COCKPIT_SHOW',
    ROLE_PROJECT_MY_SHIPMENT_CLIENTS_SHOW = 'ROLE_PROJECT_MY_SHIPMENT_CLIENTS_SHOW',
    ROLE_PROJECT_MY_SHIPMENT_WORLD_MAP_SHOW = 'ROLE_PROJECT_MY_SHIPMENT_WORLD_MAP_SHOW',
    ROLE_PROJECT_SHIPMENT_SUBSCRIPTION_SHOW = 'ROLE_PROJECT_SHIPMENT_SUBSCRIPTION_SHOW',
    ROLE_PROJECT_SHIPMENT_SUBSCRIPTION_CREATE = 'ROLE_PROJECT_SHIPMENT_SUBSCRIPTION_CREATE',
    ROLE_PROJECT_SHIPMENT_SUBSCRIPTION_DELETE = 'ROLE_PROJECT_SHIPMENT_SUBSCRIPTION_DELETE',
    ROLE_PROJECT_CHAT_SHOW = 'ROLE_PROJECT_CHAT_SHOW',
    ROLE_API_SHOW = 'ROLE_API_SHOW',
    ROLE_PROJECT_MORNING_CHECK_SHOW = 'ROLE_PROJECT_MORNING_CHECK_SHOW',
    ROLE_SHIPMENT_GEOTRACK_READ = 'ROLE_SHIPMENT_GEOTRACK_READ',
    ROLE_SHIPMENT_GEOTRACK_EDIT = 'ROLE_SHIPMENT_GEOTRACK_EDIT'
}
