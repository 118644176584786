import {Form} from "react-bootstrap";
import React from "react";
import {Control, Controller, UseFormRegister} from "react-hook-form";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import AsyncSelect from "react-select/async";

export interface SelectInputOptions {
    label: string | null,
    value: any,
}

type SelectInputProps = {
    containerClass?: string,
    label?: string,
    name: string,
    control: Control<any>,
    register?: UseFormRegister<any>,
    selectOptions?: SelectInputOptions[],

    placeholder?: string,
    isMultiple?: boolean,
    defaultValue?: any,
    creatable?: boolean,
    singleValueClasses?: string,
    labelClassName?: string,
    async?: boolean,
    promiseOptions?: (inputValue: string) => Promise<SelectInputOptions[]>,
    handleInputChange?: (newValue: string) => void,
};

const SelectInput = ({
                         name,
                         label,
                         containerClass,
                         selectOptions,
                         isMultiple = false,
                         control,
                         placeholder,
                         singleValueClasses,
                         labelClassName,
                         creatable = false,
                         async = false,
                         promiseOptions,
                         handleInputChange,
                     }: SelectInputProps) => {
    const errors = {[name]: {message: ''}};

    const classNames = {
        multiValue: () => 'bg-primary text-white',
        multiValueLabel: () => 'text-light',
        singleValue: () => singleValueClasses ?? ''
    };
    const styles = {
        multiValueRemove: (styles: any) => ({
            ...styles,
            ':hover': {
                backgroundColor: 'transparent'
            }
        })
    };

    const handlePromiseOptions = async (inputValue: string) => {
        try {
            const options = await promiseOptions?.(inputValue) ?? [];
            return options;
        } catch (error) {
            console.error(error);
            return [];
        }
    };

    return (
        <>
            <Form.Group className={containerClass}>
                {label ? <Form.Label className={labelClassName}>{label}</Form.Label> : null}

                <Controller
                    control={control}
                    name={name}
                    render={
                        (
                            {
                                field: {onChange, onBlur, name, value},
                            }
                        ) => {
                            const valueExtractor = !isMultiple
                                // on trouve une option
                                ? selectOptions?.find(o => o.value === value)
                                // on trouve toutes les options
                                : selectOptions?.filter(o => value ? value.indexOf(o.value) >= 0 : false);

                            const changeExtractor = !isMultiple
                                ? (value: any) => onChange(value?.value)
                                : (values: any) => onChange(values.map((option: any) => option.value));

                            if (creatable) {
                                return (
                                    <CreatableSelect
                                        value={valueExtractor}
                                        onChange={changeExtractor}
                                        onBlur={onBlur}
                                        options={selectOptions}
                                        placeholder={placeholder ?? label}
                                        id={name}
                                        classNames={classNames}
                                        styles={styles}
                                        isMulti={isMultiple}
                                        isClearable={true}
                                    />
                                );
                            }

                            if (async) {
                                return (
                                    <AsyncSelect
                                        defaultOptions
                                        cacheOptions
                                        isLoading={false}
                                        onBlur={onBlur}
                                        onChange={changeExtractor}
                                        value={valueExtractor}
                                        loadOptions={(inputValue, callback) => {
                                            handlePromiseOptions(inputValue).then((options) => {
                                                callback(options);
                                            });
                                        }}
                                        placeholder={placeholder ?? label}
                                        isMulti={isMultiple}
                                    />
                                );
                            }
                            return (
                                <Select
                                    value={valueExtractor}
                                    onChange={changeExtractor}
                                    onBlur={onBlur}
                                    options={selectOptions}
                                    placeholder={placeholder ?? label}
                                    id={name}
                                    classNames={classNames}
                                    styles={styles}
                                    isMulti={isMultiple}
                                    isClearable={true}
                                />
                            );
                        }}
                />

                {errors && errors[name] ? (
                    <Form.Control.Feedback type="invalid">
                        {errors[name]?.message?.toString()}
                    </Form.Control.Feedback>
                ) : null}
            </Form.Group>
        </>
    )
}

export default SelectInput;