import {normalizedType, Normalizer} from './normalizer';
import {GroupMyrudder} from '../../model/GroupMyrudder';

const GroupMyrudderNormalizer = new class implements Normalizer<GroupMyrudder> {

    normalize(data: normalizedType<GroupMyrudder>): GroupMyrudder {
        const resource = new GroupMyrudder(
            data.id,
        );

        resource.group = data.group;
        resource.roles = data.roles;
        resource.client = data.client;

        return resource;
    }

}();

export {GroupMyrudderNormalizer};