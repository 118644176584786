import Form from 'react-bootstrap/Form';
import React, {CSSProperties} from "react";
import {Control, Controller, UseFormRegister} from "react-hook-form";

interface SwitchButtonProps {
    label?: string,
    isChecked?: boolean,
    clickEvent?: any,
    styles?: CSSProperties
}

export const SwitchButton = ({label, isChecked, clickEvent, styles}: SwitchButtonProps) => {
    return (
        <Form.Switch
            className={'form-check-primary'}
            type="switch"
            label={label}
            style={styles}
            defaultChecked={isChecked}
            onClick={clickEvent}
        />
    );
}

interface ControlSwitchInputProps {
    control: Control,
    label?: string,
    name: string,
    register: UseFormRegister<any>,
    style?: CSSProperties,
    value: any,
    changeHandler?: any
    classes?: string
    labelClassName?: string
}

export const ControlSwitchInput = ({label, name, register, style, control, changeHandler, classes, labelClassName}: ControlSwitchInputProps) => {
    return (
        <>
            {
                label ? <p className={labelClassName+' label'}>{label}</p> : null
            }
            <Controller
                control={control}
                name={name}
                defaultValue={false}
                render={
                    (
                        {
                            field: {onChange, name, value},
                        }
                    ) => {
                        return (
                            <Form.Switch
                                className={classes+' form-check-primary'}
                                type="switch"
                                style={style}
                                id={name}
                                {...register(name)}
                                onChange={(value) => {onChange(value); changeHandler?.()}}
                                checked={value}
                            />
                        )
                    }
                }
            />
        </>
    );
}