import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import ProfileDropdown from "../../components/ProfileDropdown";

import logo from "../../assets/images/myrudder-digital-forwarder.png";
import profilePic from '../../assets/images/users/user-1.jpg';

interface HeaderProps {
    hideLogo?: boolean;
    openLeftMenuCallBack?: Function
    handleLogout: () => void;
}

const ProfileMenus = (onClick: HeaderProps["handleLogout"]) => [
    {
        label: 'Mon profil',
        icon: 'fe-user',
        redirectTo: '/profil'
    },
    {
        label: 'Déconnexion',
        icon: 'fe-log-out',
        onClick: onClick,
    }
];

const Header: React.FC<HeaderProps> = ({hideLogo, openLeftMenuCallBack, handleLogout}) => {

    const [isopen, setIsopen] = React.useState<boolean>(false);

    const containerCssClasses: string = !hideLogo ? 'container-fluid' : '';

    /*const { layoutType, leftSideBarType } = useSelector((state: RootState) => ({
        layoutType: state.Layout.layoutType,
        leftSideBarType: state.Layout.leftSideBarType,
    }));*/

    /**
     * Importation de la méthode du theme UBold : "Toggles the left sidebar width" (trigger menu latéral gauche)
     */
    /*const toggleLeftSidebarWidth = () => {
        if (leftSideBarType === 'default' || leftSideBarType === 'compact')
            dispatch(changeSidebarType(SideBarTypes.LEFT_SIDEBAR_TYPE_CONDENSED));
        if (leftSideBarType === 'condensed') dispatch(changeSidebarType(SideBarTypes.LEFT_SIDEBAR_TYPE_DEFAULT));
    };*/

    /**
     * Importation de la méthode du theme UBold : "Toggle the leftmenu when having mobile screen"
     */
    const handleLeftMenuCallBack = () => {
        setIsopen(!isopen);
        if (openLeftMenuCallBack) openLeftMenuCallBack();
    };

    return (
        <>
            <header className="navbar-custom bg-white bordered-nav">
                <div className={containerCssClasses+' d-flex justify-content-between align-items-center'}>
                    {/* Section logo */}
                    <div className={'d-flex align-items-center'}>
                        <div className="logo-box d-flex align-items-center justify-content-center">
                            <Link to="/" className="logo logo-light">
                            <span className="logo-lg d-flex align-items-center">
                                <img
                                    alt=""
                                    src={logo}
                                    height="50"
                                    className="d-inline-block align-top ps-2"
                                />{' '}
                            </span>
                                <span className="logo-sm">
                                <img src={logo} alt="" height="40" />
                            </span>
                            </Link>
                        </div>

                        {/* Bouton toggle menu gauche */}
                        <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
                            {/*{layoutType !== LayoutTypes.LAYOUT_HORIZONTAL && (
                            <li>
                                <button
                                    className="button-menu-mobile waves-effect waves-light d-none d-lg-block"
                                    onClick={toggleLeftSidebarWidth}
                                >
                                    <i className="fe-menu"></i>
                                </button>
                            </li>
                        )}*/}
                            <li>
                                <button
                                    className="button-menu-mobile open-left d-lg-none d-block waves-effect waves-light"
                                    onClick={handleLeftMenuCallBack}
                                >
                                    <i className="fe-menu" />
                                </button>
                            </li>
                            {/* Mobile menu toggle (Horizontal Layout) */}
                            <li>
                                <Link
                                    to="#"
                                    className={classNames('navbar-toggle nav-link', {
                                        open: isopen,
                                    })}
                                    onClick={handleLeftMenuCallBack}
                                >
                                    <div className="lines">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <h4 className={'text-primary text-font-tertiary d-none d-lg-block'}>
                        Jupiter Admin
                    </h4>

                    {/* Menu utilisateur droit */}
                    <ul className="list-unstyled topnav-menu float-end mb-0 bg-transparent">
                        <li className="dropdown notification-list topbar-dropdown " color={'var(--ct-text-color-h)'} >
                            <ProfileDropdown
                                profilePic={profilePic}
                                menuItems={ProfileMenus(handleLogout)}
                                username={'Michel'}
                                userTitle={'Founder'}
                            />
                        </li>
                    </ul>
                </div>
            </header>
        </>
    );
}

export default Header;