import {Customer} from '../../model/Customer';
import {useEffect, useState} from "react";
import {APICore} from "./apiCore";

const api = new APICore();

export const GetAllCustomers = () => {
    const [data, setData] = useState<Customer[]>([]);
    const [next, setNext] = useState(true);
    const [page, setPage] = useState(1);

    const fetchData = async () => {
        const lot: Customer[] = [];

        try {
            const {data: response} = await api.get('/api/clients', {page: page});

            response.map((customer: Customer) => {
                if (data.some((g: Customer) => g.id === customer.id)) return customer;
                setData((data) => [
                        ...data,
                        new Customer(
                            customer.id,
                            customer.name
                        ),
                    ],
                );
                lot.push(customer);
                return customer;
            });
        } catch (e) {
            console.log(e);
        }

        return lot;
    };
    useEffect(() => {
        if (next) {
            fetchData()
                .then((r) => {
                    if (r.length < 1) {
                        setNext(false);
                    } else {
                        setPage(page + 1);
                    }
                });
        }
        // eslint-disable-next-line
    }, [page]);

    return data;
};
