import {normalizedType, Normalizer} from './normalizer';
import {Group} from '../../model/Group';

const GroupNormalizer = new class implements Normalizer<Group> {

    normalize(data: normalizedType<Group>): Group {
        const resource = new Group(
            data.id,
            data.name,
            data.superGroup,
            data.pool,
            data.contactClientType,
            data.customeoTeamName,
            data.customeoTeamId,
        );
        resource.groupJupiter = data.groupJupiter;
        resource.groupMyrudder = data.groupMyrudder;
        resource.groupWorkflow = data.groupWorkflow;

        return resource;
    }

}();

export {GroupNormalizer};