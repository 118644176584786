import React, {useEffect} from 'react';
import {Table} from 'react-bootstrap';
import {renderListingProps} from './ListingController';
import {Identifiable} from '../model/interface/Identifiable';
import {formatDate} from '../utils';
import Spinner from './Spinner';
import {ReactElement} from 'react';
import useDatasource from '../hooks/useDatasource';
import {
    UserJupiterNormalizer,
} from '../services/normalizer/UserJupiterNormalizer';
import {
    UserMyrudderNormalizer,
} from '../services/normalizer/UserMyrudderNormalizer';
import {
    UserWorkflowNormalizer,
} from '../services/normalizer/UserWorkflowNormalizer';
import {GroupNormalizer} from '../services/normalizer/GroupNormalizer';
import {ClientNormalizer} from '../services/normalizer/ClientNormalizer';
import {
    GroupJupiterNormalizer,
} from '../services/normalizer/GroupJupiterNormalizer';
import {
    GroupMyrudderNormalizer,
} from '../services/normalizer/GroupMyrudderNormalizer';
import {
    GroupWorkflowNormalizer,
} from '../services/normalizer/GroupWorkflowNormalizer';

export interface ResourceListingActionProps<ResourceT extends Identifiable> {
    resource: ResourceT;
    deleteResource: (data: ResourceT) => void;
    duplicateRoute?: string;
    connectAs?: boolean;

}

interface ColumnRelationProps<ResourceR extends Identifiable> {
    property: Extract<keyof ResourceR, string>;
    model: ResourceR;
}

export interface ListingProps<ResourceT extends Identifiable> extends renderListingProps<ResourceT> {
    columns: Array<{
        label: string
        property: Extract<keyof ResourceT, string>,
        type?: string
        relation?: ColumnRelationProps<any>
    }>,
    tableStyles?: {
        theadClasses?: string,
        tbodyClasses?: string,
        stripe?: boolean,
        bordered?: boolean,
        hover?: boolean
    },
    actions?: {
        label: string,
        renderActions: (props: ResourceListingActionProps<ResourceT>) => ReactElement;
    },
    paginated?: boolean,

}

// replace iri by name

export default function TableListing<ResourceT extends Identifiable>({...props}: ListingProps<ResourceT>) {
    const resources: any = props.resources[0];
    const [isLoading, setIsLoading] = React.useState(true);
    const {get: getClient} = useDatasource('clients', ClientNormalizer);
    const {get: getGroup} = useDatasource('groups', GroupNormalizer);
    const {get: getUserJupiter} = useDatasource(
        'user_jupiters',
        UserJupiterNormalizer,
    );
    const {get: getUserMyrudder} = useDatasource(
        'user_myrudders',
        UserMyrudderNormalizer,
    );
    const {get: getUserWorkflow} = useDatasource(
        'user_workflows',
        UserWorkflowNormalizer,
    );
    const {get: getGroupJupiter} = useDatasource(
        'group_jupiters',
        GroupJupiterNormalizer,
    );
    const {get: getGroupMyrudder} = useDatasource(
        'group_myrudders',
        GroupMyrudderNormalizer,
    );
    const {get: getGroupWorkflow} = useDatasource(
        'group_workflows',
        GroupWorkflowNormalizer,
    );

    const isIri = (value: string) => {
        const regex = /^(\/app_dev.php)?\/api\/\w+\/\d+$/;
        return regex.test(value);
    };
    const replaceIri = async (resources: any) => {
        if (resources) {
            const updatedResources = await Promise.all(resources.map(async (resource: any) => {
                const projects: string[] = [];
                if (resource.userJupiter) projects.push(resource.userJupiter);
                if (resource.userMyrudder) projects.push(resource.userMyrudder);
                if (resource.userWorkflow) projects.push(resource.userWorkflow);
                const GroupProjects: string[] = [];
                if (resource.groupJupiter) GroupProjects.push(resource.groupJupiter);
                if (resource.groupMyrudder) GroupProjects.push(resource.groupMyrudder);
                if (resource.groupWorkflow) GroupProjects.push(resource.groupWorkflow);

                if (resource.clients) {
                    // eslint-disable-next-line
                    const clientsNamePromises = resource.clients.map((client: any) => {
                        if (!client) {
                            return '';
                        }
                        if (isIri(client)) {
                            const id = parseInt(client.split('/').at(-1) || '');
                            if (!isNaN(id)) {
                                return getClient(id)
                                    .then((data: any) => data.name);
                            }
                        }
                    });
                    const clientsNames = await Promise.all(clientsNamePromises);
                    resource.clientNames = clientsNames ?? resource.clients;
                }
                if (resource.groups) {
                    // eslint-disable-next-line
                    const groupsNamePromises = resource.groups.map((group: any) => {
                        if (!group) {
                            return '';
                        }
                        if (isIri(group)) {
                            const id = parseInt(group.split('/').at(-1) || '');
                            if (!isNaN(id)) {
                                return getGroup(id)
                                    .then((data: any) => data.name);
                            }
                        }
                    });
                    const groupsNames = await Promise.all(groupsNamePromises);
                    resource.profiles = groupsNames ?? resource.groups;
                }
                if (projects.length > 0) {
                    // eslint-disable-next-line
                    const projectsNamePromises = projects.map((project: any) => {
                        if (!project) {
                            return '';
                        }
                        if (isIri(project)) {
                            const id = parseInt(project.split('/').at(-1) ||
                                '');
                            if (!isNaN(id)) {
                                if (project.includes('user_jupiters')) {
                                    return getUserJupiter(id)
                                        .then((data: any) => {
                                            if (data.enabled) {
                                                return 'Jupiter';
                                            }
                                            return '';
                                        });
                                }
                                if (project.includes('user_myrudders')) {
                                    return getUserMyrudder(id)
                                        .then((data: any) => {
                                            if (data.enabled) {
                                                return 'Myrudder';
                                            }
                                            return '';
                                        });
                                }
                                if (project.includes('user_workflows')) {
                                    return getUserWorkflow(id)
                                        .then((data: any) => {
                                            if (data.enabled) {
                                                return 'EasySurf';
                                            }
                                            return '';
                                        });
                                }
                            }
                        }
                    });
                    const projectsNames = await Promise.all(projectsNamePromises);
                    resource.projects = projectsNames ?? resource.projects;
                }
                if (GroupProjects.length > 0) {
                    // eslint-disable-next-line
                    const projectsNamePromises = GroupProjects.map((project: any) => {
                        if (!project) {
                            return '';
                        }
                        if (isIri(project)) {
                            const id = parseInt(project.split('/').at(-1) ||
                                '');
                            if (!isNaN(id)) {
                                if (project.includes('group_jupiters')) {
                                    return getGroupJupiter(id)
                                        .then((data: any) => {
                                            if (data.roles.length > 0) {
                                                return 'Jupiter';
                                            }
                                            return '';
                                        });
                                }
                                if (project.includes('group_myrudders')) {
                                    return getGroupMyrudder(id)
                                        .then((data: any) => {
                                            if (data.roles.length > 0) {
                                                return 'Myrudder';
                                            }
                                            return '';
                                        });
                                }
                                if (project.includes('group_workflows')) {
                                    return getGroupWorkflow(id)
                                        .then((data: any) => {
                                            if (data.roles.length > 0) {
                                                return 'Workflow';
                                            }
                                            return '';
                                        });
                                }
                            }
                        }
                    });
                    const projectsNames = await Promise.all(projectsNamePromises);
                    resource.projects = projectsNames ??
                        resource.projects;
                }

                return resource;
            }));
            return updatedResources;
        }
        return resources;
    };

    const deleteResource = () => {
    };
    useEffect(() => {
        async function fetchResources(resources: any) {
            setIsLoading(true);
            await replaceIri(resources).then(() => setIsLoading(false));
        }

        fetchResources(resources);
        // eslint-disable-next-line
    }, [resources]);

    const columnsSize: number = Math.floor(12 / (props.columns.length + (props.actions ? 1 : 0)));
    const specificCol = (index: number, property: string) => {
        if (index === 0) return 3;
        if (property === 'email') return 2;

        return columnsSize;
    }

    return !props.isLoading ?
        <>
            {
                isLoading ? <div
                    className={'text-center mb-0'}><Spinner/>
                </div> : null
            }
            <Table striped={props.tableStyles?.stripe}
                   bordered={props.tableStyles?.bordered}
                   hover={props.tableStyles?.hover} className={'bg-white mt-1'} style={{tableLayout: 'fixed'}}>
                <thead className={props.tableStyles?.theadClasses}>
                <tr>
                    {
                        props.columns.map(({label, property}, index) =>
                            <th className={`col-${specificCol(index, property)}`}
                                key={index}>{label}</th>,
                        )
                    }
                    {
                        props.actions ?
                            <th className={'col-2'}>Actions</th>
                            : null
                    }
                </tr>
                </thead>
                <tbody className={props.tableStyles?.tbodyClasses}>
                {
                    resources.map((resource: any) =>
                        <tr key={resource.id}>
                            {
                                props.columns.map((column, index) =>
                                    <td key={index}>
                                        {
                                            column.relation ?
                                                resource[column.property]?.map((
                                                    relation: any,
                                                    index: number,
                                                ) => (
                                                    <React.Fragment key={index}>
                                                        <span
                                                            className={'badge bg-primary text-light'}>{relation[(column.relation?.property ||
                                                            '')]?.toString()}</span>
                                                        &#8239;
                                                    </React.Fragment>
                                                ))
                                                : column.type && column.type ===
                                                'date' ?
                                                    `${formatDate(
                                                        new Date(resource[column.property]),
                                                        'fr-FR',
                                                        {},
                                                        'date',
                                                    )}`
                                                    : typeof resource[column.property] ==
                                                    'boolean' ?
                                                        resource[column.property] ?
                                                            (<span
                                                                className={'badge bg-success text-light'}>
                                                        <i className={'fas fa-check'}></i>
                                                    </span>)
                                                            :
                                                            (<span
                                                                className={'badge bg-danger text-light'}>
                                                        <i className={'fas fa-times'}></i>
                                                    </span>)
                                                        : resource[column.property] instanceof
                                                        Date ?
                                                            `${formatDate(
                                                                resource[column.property],
                                                                'fr-FR',
                                                                {
                                                                    year: 'numeric',
                                                                    day: '2-digit',
                                                                    month: 'short',
                                                                },
                                                                'date',
                                                            )} 
                                                à 
                                                ${formatDate(
                                                                resource[column.property],
                                                                'fr-FR',
                                                                {},
                                                                'time',
                                                            )}`
                                                            : resource[column.property] instanceof
                                                            Array<any> ?
                                                                resource[column.property].map(
                                                                    (
                                                                        property: any,
                                                                        index: number,
                                                                    ) => (
                                                                        <React.Fragment
                                                                            key={index}>
                                                                            <span
                                                                                className={'badge bg-primary text-light'}>{property.toString()}</span>
                                                                            &#8239;
                                                                        </React.Fragment>
                                                                    ),
                                                                )
                                                                : resource[column.property]?.toString()
                                        }
                                    </td>,
                                )
                            }
                            {
                                props.actions ?
                                    <td>{props.actions.renderActions({
                                        deleteResource,
                                        resource,
                                    })} </td>
                                    : null
                            }
                        </tr>,
                    )
                }
                </tbody>
            </Table>
        </>
        : <div className="text-center pt-2 mb-0">
            <Spinner/>
        </div>;
}