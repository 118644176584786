import React, {ReactElement} from 'react';
import Accordion from 'react-bootstrap/Accordion';

type AccordionItems = {
    eventKey?: string,
    header: string,
    children: ReactElement,
    headingIcon?: string,
    collapse?: boolean
}

interface CollapsiblePanelProps {
    accordionItems: AccordionItems[],
    accordionHeaderClasses?: string,
}

const CollapsiblePanel = ({accordionItems, accordionHeaderClasses}: CollapsiblePanelProps) => {
    return (
        <>
            <Accordion defaultActiveKey={"0"} className={"mb-1 lg:overflow-auto lg:mb-0 lg:pb-4"}>
                {
                    accordionItems.map((item, index) => (
                        <Accordion.Item key={index} eventKey={item.collapse ? "1" : item.eventKey ? item.eventKey : "0"} className={"bg-white"}>
                            <Accordion.Header className={accordionHeaderClasses ?? ''}>
                                <span className={"card-title header-title mb-0"}>
                                    {
                                        item.headingIcon ?
                                            <i className={item.headingIcon+" me-1"}></i>
                                            : null
                                    }
                                    {item.header}
                                </span>
                            </Accordion.Header>
                            <Accordion.Body>
                                {item.children}
                            </Accordion.Body>
                        </Accordion.Item>
                    ))
                }
            </Accordion>
        </>
    )
}

export default CollapsiblePanel;
