import {normalizedType, Normalizer} from './normalizer';
import {UserJupiter} from '../../model/UserJupiter';

const UserJupiterNormalizer = new class implements Normalizer<UserJupiter> {

    normalize(data: normalizedType<UserJupiter>): UserJupiter {
        const resource = new UserJupiter(
            data.id,
        );

        resource.enabled = data.enabled;
        resource.user = data.user;

        return resource;
    }
}();

export {UserJupiterNormalizer};