import {Identifiable} from './interface/Identifiable';
import {WorkflowRoles} from './enum/roles/Workflow';

export class GroupWorkflow implements Identifiable {
    roles: Array<WorkflowRoles | string> = [];
    group: string | null = null;

    constructor(
        readonly id: number = 0,
    ) {
    }
}
