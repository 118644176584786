import ButtonComponent from './ButtonComponent';
import classNames from 'classnames';
import React, {useContext} from 'react';
import {FlashContext} from "../context/FlashContext";

export function FormActions({isLoading}: {isLoading: boolean}) {
    const {removeFlash} = useContext(FlashContext);
    return (
        <div className={'form-actions border-top sticky-bottom bg-white'}>
            <ButtonComponent
                buttonLabel={'Sauvegarder'}
                buttonType={'submit'}
                buttonIcon={classNames(
                    isLoading
                        ? 'spinner-border text-white spinner-border-sm'
                        : 'fas fa-save',
                )}
                disabled={isLoading}
                handleClickEvent={() => {
                    removeFlash()
                }}
            />
        </div>
    );
}