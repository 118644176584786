import {normalizedType, Normalizer} from './normalizer';
import {UserMyrudder} from '../../model/UserMyrudder';

const UserMyrudderNormalizer = new class implements Normalizer<UserMyrudder> {

    normalize(data: normalizedType<UserMyrudder>): UserMyrudder {
        const resource = new UserMyrudder(
            data.id,
        );

        resource.enabled = data.enabled;
        resource.user = data.user;
        resource.language = data.language;
        resource.phone = data.phone;
        resource.admin = data.admin;

        return resource;
    }
}();

export {UserMyrudderNormalizer};