import {renderFormProps} from '../../../FormController';
import {
    AccessProfilePermissionsTable,
} from '../../../../components/app/App/AccessProfilePermissionsTable';
import React from 'react';
import CollapsiblePanel from '../../../../components/CollapsiblePanel';
import {JupiterRoles} from '../../../../model/enum/roles/Jupiter';
import {GroupJupiter} from '../../../../model/GroupJupiter';

interface AccessProfilePermissionsProps extends renderFormProps<GroupJupiter> {
    resource: GroupJupiter;
}

export const JupiterPermissions = ({
    register,
    resource,
    setValue,
}: AccessProfilePermissionsProps) => {

    return <CollapsiblePanel
        accordionHeaderClasses={'bg-light'}
        accordionItems={
            [
                {
                    eventKey: '0',
                    header: 'Paramètres',
                    children:
                        <AccessProfilePermissionsTable
                            id={'groupJupiter'}
                            register={register}
                            setValue={setValue}
                            resource={resource}
                            modules={[
                                {
                                    label: 'Utilisateurs',
                                    icon: 'fas fa-user-cog',
                                    permissions: [
                                        {
                                            rights: [
                                                {
                                                    name: JupiterRoles.ROLE_JUPITER_USER_SHOW,
                                                    value: JupiterRoles.ROLE_JUPITER_USER_SHOW,
                                                    active: true,
                                                },
                                                {
                                                    name: JupiterRoles.ROLE_JUPITER_USER_CREATE,
                                                    value: JupiterRoles.ROLE_JUPITER_USER_CREATE,
                                                    active: true,
                                                },
                                                {
                                                    name: JupiterRoles.ROLE_JUPITER_USER_EDIT,
                                                    value: JupiterRoles.ROLE_JUPITER_USER_EDIT,
                                                    active: true,
                                                },
                                                {
                                                    name: JupiterRoles.ROLE_JUPITER_USER_DELETE,
                                                    value: JupiterRoles.ROLE_JUPITER_USER_DELETE,
                                                    active: true,
                                                },
                                            ], label: 'Permission de base',
                                        },
                                    ],
                                },
                                {
                                    label: 'Profils d\'accès',
                                    icon: 'fas fa-users-cog',
                                    permissions: [
                                        {
                                            rights: [
                                                {
                                                    name: JupiterRoles.ROLE_JUPITER_ACCESS_PROFILE_SHOW,
                                                    value: JupiterRoles.ROLE_JUPITER_ACCESS_PROFILE_SHOW,
                                                    active: true,
                                                },
                                                {
                                                    name: JupiterRoles.ROLE_JUPITER_ACCESS_PROFILE_CREATE,
                                                    value: JupiterRoles.ROLE_JUPITER_ACCESS_PROFILE_CREATE,
                                                    active: true,
                                                },
                                                {
                                                    name: JupiterRoles.ROLE_JUPITER_ACCESS_PROFILE_EDIT,
                                                    value: JupiterRoles.ROLE_JUPITER_ACCESS_PROFILE_EDIT,
                                                    active: true,
                                                },
                                                {
                                                    name: JupiterRoles.ROLE_JUPITER_ACCESS_PROFILE_DELETE,
                                                    value: JupiterRoles.ROLE_JUPITER_ACCESS_PROFILE_DELETE,
                                                    active: true,
                                                },
                                            ], label: 'Permission de base',
                                        },
                                    ],
                                },

                            ]}
                        />,
                },
            ]
        }
    />;
};