import {Col, Row} from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import React from 'react';
import {Identifiable} from '../model/interface/Identifiable';

export interface PaginateProps<ResourceT extends Identifiable> {
    resources: ResourceT[];
    resourcesPerPage: number;
    paginate: any;
    changeItemsPerPage: any;
    currentPage: number;
}

const resourcesPerPageChoices = [30];

export function Paginate<ResourceT extends Identifiable>({
    resources,
    paginate,
    resourcesPerPage,
    changeItemsPerPage,
    currentPage,
}: PaginateProps<ResourceT>) {

    const totalItems = parseInt(resources[1] ? resources[1].toString() : '0');

    return (
        <>
            <Row>
                <p className={'mb-0'}>{`${totalItems} entrée${totalItems > 1
                    ? 's'
                    : ''}`}</p>
            </Row>
            <Row className={'align-items-center'}>
                <Col className={'pt-2'}>
                    <p className={'mb-0'}>Nombre d'éléments par page :</p>
                    <ul className={'pagination'}>
                        {
                            resourcesPerPageChoices.map((choice, index) => (
                                <li key={index} className={'page-item '}>
                                    <button className={`page-link ${choice ===
                                    resourcesPerPage
                                        ? 'bg-primary text-light'
                                        : ''}`} onClick={() => {
                                        changeItemsPerPage(choice);
                                    }}>{choice}</button>
                                </li>
                            ))
                        }
                    </ul>
                </Col>
                <Col>
                    <div className={'pt-3'}>
                        {
                            totalItems > resourcesPerPage ?
                                <ReactPaginate
                                    forcePage={currentPage - 1}
                                    onPageChange={paginate}
                                    pageCount={Math.ceil(totalItems /
                                        resourcesPerPage)}
                                    previousLabel={<>
                                        <i className={'fas fa-chevron-left me-1'}></i>
                                        <span>Précédent</span>
                                    </>}
                                    nextLabel={<>
                                        <span>Suivant</span>
                                        <i className={'fas fa-chevron-right ms-1'}></i>
                                    </>}
                                    containerClassName={'pagination justify-content-end'}
                                    pageLinkClassName={'page-link'}
                                    previousLinkClassName={'page-link'}
                                    nextLinkClassName={'page-link'}
                                    activeLinkClassName={'bg-primary text-light'}
                                    disabledClassName={'text-light pe-none'}
                                />
                                : null
                        }
                    </div>
                </Col>
            </Row>
        </>
    );
}