import {routesPosition, RoutesProps} from '../router.config';
import ListingController from '../../components/ListingController';
import TableListing, {ListingProps} from '../../components/TableListing';
import UserFilter from '../../forms/app/filters/UserFilter';
import {User} from '../../model/User';
import ResourceListingActions
    from '../../components/app/App/ResourceListingActions';
import {Paginate} from '../../components/Paginate';
import {Group, Profiles} from '../../model/Group';
import UserType from '../../forms/app/types/UserType';
import {Link} from 'react-router-dom';
import {UserNormalizer} from '../../services/normalizer/UserNormalizer';
import {GroupNormalizer} from '../../services/normalizer/GroupNormalizer';
import {AccessProfileType} from '../../forms/app/types/AccessProfileType';
import AccessProfileFilter from '../../forms/app/filters/AccessProfileFilter';

const userListingColumns: ListingProps<User>['columns'] = [
    {label: 'Client(s)', property: 'clientNames'},
    {label: 'Prénom', property: 'firstname'},
    {label: 'Nom', property: 'lastname'},
    {
        label: 'Profils d\'accès',
        property: 'profiles',
    },
    {label: 'E-mail', property: 'email'},
    {label: 'Projet(s)', property: 'projects'},
];

export const accessProfileRoute: RoutesProps = {
    path: '/profils-acces',
    position: routesPosition.leftSideBar,
    handle: {
        crumb: 'Profils d\'accès',
    },
    accessControl: [Profiles.ROLE_ADMIN, Profiles.ROLE_SUPER_ADMIN],
    children: [
        {
            path: '/profils-acces',
            name: 'Profils d\'accès',
            icon: 'fas fa-users-cog',
            position: routesPosition.leftSideBar,
            element:
                <ListingController<Group>
                    title={
                        <div className={'d-flex flex-row mt-1'}>
                            <h1>Profils d'accès</h1>
                            <div className={'form-actions'} style={{
                                marginLeft: 'auto',
                            }}>
                                <Link to={`/profils-acces/ajouter`}
                                      className={'btn btn-primary'}><i
                                    className="fas fa-plus"></i><span
                                    className={'ps-1'}>Ajouter</span>
                                </Link>
                            </div>
                        </div>
                    }
                    renderListing={(props) =>
                        <div className={'h-100 overflow-auto'}>
                            <TableListing
                                {...props}
                                columns={[
                                    {label: 'Nom', property: 'name'},
                                    {label: 'Projets', property: 'projects'}]}
                                tableStyles={{theadClasses: 'bg-white'}}
                                actions={{
                                    label: 'Actions',
                                    renderActions: (props) =>
                                        <ResourceListingActions {...props}/>,
                                }}
                            />
                        </div>
                    }
                    renderPagination={(props) => <Paginate {...props}/>}
                    renderFilter={(props) => <AccessProfileFilter {...props}/>}
                    datasource={'groups'}
                    normalizer={GroupNormalizer}
                />,
        },
        {
            path: '/profils-acces/:id',
            element: <AccessProfileType/>,
            handle: {
                crumb: 'Modifier',
            },
            position: routesPosition.hiddenRoutes,
        },
        {
            path: '/profils-acces/ajouter',
            element: <AccessProfileType/>,
            handle: {
                crumb: 'Ajouter',
            },
            position: routesPosition.hiddenRoutes,
        },
    ],
};
export const usersRoute: RoutesProps = {
    path: '/utilisateurs',
    position: routesPosition.leftSideBar,
    handle: {
        crumb: 'Utilisateurs',
    },
    accessControl: [
        Profiles.ROLE_USER,
        Profiles.ROLE_ADMIN,
        Profiles.ROLE_SUPER_ADMIN],
    children: [
        {
            path: '/utilisateurs',
            name: 'Utilisateurs',
            icon: 'fas fa-user-cog',
            position: routesPosition.leftSideBar,
            element:
                <ListingController<User>
                    title={
                        <div className={'d-flex flex-row mt-1'}>
                            <h1>Utilisateurs</h1>
                            <div className={'form-actions'} style={{
                                marginLeft: 'auto',
                            }}>
                                <Link to={'/utilisateurs/ajouter'}
                                      className={'btn btn-primary'}><i
                                    className="fas fa-plus"></i><span
                                    className={'ps-1'}>Ajouter</span>
                                </Link>
                            </div>
                        </div>
                    }
                    renderListing={(props) =>
                        <div className={'h-100 overflow-auto'}>
                            <TableListing
                                {...props}
                                columns={userListingColumns}
                                tableStyles={{theadClasses: 'bg-white'}}
                                actions={{
                                    label: 'Actions',
                                    renderActions: (props) =>
                                        <ResourceListingActions
                                            {...props}
                                            duplicateRoute={'/dupliquer/'}
                                            connectAs={true}
                                        />,
                                }}
                            />
                        </div>
                    }
                    renderPagination={(props) => <Paginate {...props}/>}
                    renderFilter={(props) => <UserFilter {...props}/>}
                    datasource={'users'}
                    normalizer={UserNormalizer}
                    filterDefault={{userProjectEnabled: 'all'}}
                />,
        },
        {
            path: '/utilisateurs/:id',
            element: <UserType duplicate={false}/>,
            handle: {
                crumb: 'Modifier',
            },
            position: routesPosition.hiddenRoutes,
        }, {
            path: '/utilisateurs/ajouter',
            element: <UserType duplicate={false}/>,
            handle: {
                crumb: 'Ajouter',
            },
            position: routesPosition.hiddenRoutes,
        },
        {
            path: '/utilisateurs/dupliquer/:id',
            element: <UserType duplicate={true}/>,
            handle: {
                crumb: 'Dupliquer',
            },
            position: routesPosition.hiddenRoutes,
        },
    ],
    index: false,
};
