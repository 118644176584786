import {Identifiable} from './interface/Identifiable';

export class UserJupiter implements Identifiable {

    user: string | null = null;
    enabled: boolean = false;

    constructor(
        readonly id: number = 0,
    ) {
    }
}
