import React, {useEffect} from 'react';
import {Container, Row, Col, Card} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import logo from '../../assets/images/myrudder-digital-forwarder.png';

interface AccountLayoutProps {
    helpText?: string;
    bottomLinks?: any;
    isCombineForm?: boolean;
    children?: any;
}

const AuthLayout = ({helpText, bottomLinks, children, isCombineForm}: AccountLayoutProps) => {
    useEffect(() => {
        if (document.body) document.body.classList.add('authentication-bg', 'authentication-bg-pattern');

        return () => {
            if (document.body) document.body.classList.remove('authentication-bg', 'authentication-bg-pattern');
        };
    }, []);

    return (
        <>
            <div className="account-pages mt-5 mb-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={isCombineForm ? 9 : 4} style={{
                            border: `1px solid #224EE6`,
                            borderRadius: '10px',
                        }}>
                            <Card className="bg-pattern bg-white">
                                <Card.Body className="p-4">
                                    <div className="text-center w-75 m-auto">
                                        <div className="auth-logo">
                                            <Link to="/" className="logo logo-dark text-center">
                                                <span className="logo-lg">
                                                    <img src={logo} alt="" height="50"/>
                                                </span>
                                            </Link>
                                        </div>
                                        <p className="text-muted mb-4 mt-3">{helpText}</p>
                                    </div>
                                    {children}
                                </Card.Body>
                            </Card>

                            {/* bottom links */}
                            {bottomLinks}
                            <div className={"mb-1"}></div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default AuthLayout;
