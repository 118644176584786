import {normalizedType, Normalizer} from './normalizer';
import {Customer} from '../../model/Customer';

const ClientNormalizer = new class implements Normalizer<Customer> {

    normalize(data: normalizedType<Customer>): Customer {
        const resource = new Customer(
            data.id,
            data.name,
        );


        return resource;
    }

}();

export {ClientNormalizer};