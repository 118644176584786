import {normalizedType, Normalizer} from './normalizer';
import {UserWorkflow} from '../../model/UserWorkflow';

const UserWorkflowNormalizer = new class implements Normalizer<UserWorkflow> {

    normalize(data: normalizedType<UserWorkflow>): UserWorkflow {
        const resource = new UserWorkflow(
            data.id,
        );

        resource.enabled = data.enabled;
        resource.user = data.user;

        return resource;
    }
}();

export {UserWorkflowNormalizer};