import {FormController} from '../../FormController';
import React, {useContext, useEffect, useState} from 'react';
import {Tabs} from '../../../components/Tabs/Tabs';
import {JupiterPermissions} from './Group/JupiterPermissions';
import {MyrudderPermissions} from './Group/MyrudderPermissions';
import {WorkflowPermissions} from './Group/WorkflowPermissions';
import {Group} from '../../../model/Group';
import {FormActions} from '../../../components/FormActions';
import {Link, useParams, useNavigate} from 'react-router-dom';
import {FieldValues} from 'react-hook-form';
import {
    FlashContext,
    FlashContextInterface,
} from '../../../context/FlashContext';
import {GroupJupiter} from '../../../model/GroupJupiter';
import {GroupMyrudder} from '../../../model/GroupMyrudder';
import {GroupWorkflow} from '../../../model/GroupWorkflow';
import {JupiterRoles} from '../../../model/enum/roles/Jupiter';
import {MyrudderRoles} from '../../../model/enum/roles/Myrudder';
import {WorkflowRoles} from '../../../model/enum/roles/Workflow';
import Spinner from '../../../components/Spinner';
import {GroupNormalizer} from '../../../services/normalizer/GroupNormalizer';
import {GroupSettings} from './Group/GroupSettings';
import {
    GroupJupiterNormalizer,
} from '../../../services/normalizer/GroupJupiterNormalizer';
import {
    GroupMyrudderNormalizer,
} from '../../../services/normalizer/GroupMyrudderNormalizer';
import {
    GroupWorkflowNormalizer,
} from '../../../services/normalizer/GroupWorkflowNormalizer';
import useDatasource, {Datasource} from '../../../hooks/useDatasource';

async function UpdateGroup(
    data: FieldValues,
    resource: Group | GroupJupiter | GroupMyrudder | GroupWorkflow | null,
    datasource: Datasource<Group | GroupJupiter | GroupMyrudder | GroupWorkflow>,
    addSuccess: FlashContextInterface['addSuccess'],
    loadStart: () => void,
    loadEnd: () => void,
    setIsCreatedId: (id: number) => void,
    isCreatedId: number | null,
    setGroupProjectId: (
        id: number | null,
        groupProject: 'groupWorkflow' | 'groupMyrudder' | 'groupJupiter',
    ) => void,
) {
    loadStart();

    if (resource instanceof Group && resource.id) {
        Object.entries(data).forEach(([property, value]) => {
            if (value) {
                if (property === 'name') {
                    resource.name = value;
                }
                if (property === 'pool') {
                    resource.pool = value;
                }
                if (property === 'contactClientType') {
                    resource.contactClientType = value.value;
                }
            } else {
                console.warn(`Property "${property}" does not exist on type ${typeof value} of Group. Field was ignored.`);
            }

        });

        const cleanGroup: any = {...resource};
        delete cleanGroup.groupJupiter;
        delete cleanGroup.groupMyrudder;
        delete cleanGroup.groupWorkflow;

        datasource.update(cleanGroup, 'groups').then(() => {
            addSuccess('Le groupe a été mis à jour.');
        });
    } else if (resource instanceof GroupJupiter) {
        const request = resource.id ? resource : {
            roles: new Array<JupiterRoles | string>(),
            group: `/api/groups/${isCreatedId}`,
        };
        Object.entries(data).forEach(([property, value]) => {
            if (property in JupiterRoles) {
                if (value) {
                    if (property ===
                        JupiterRoles.ROLE_JUPITER_USER_EDIT.toString()) {
                        request.roles.push(MyrudderRoles.ROLE_PROJECT_CLIENT_SHOW.toString());
                    }
                    if (!request.roles.includes(property.toString())) {
                        request.roles.push(property.toString());
                    }
                } else {
                    if (request.roles.includes(property.toString())) {
                        const roleIndex = request.roles.findIndex((val) => val ===
                            property.toString());
                        request.roles.splice(roleIndex, 1);
                    }
                }
            } else {
                console.warn(`Property "${property}" does not exist on GroupJupiter. Field was ignored.`);
            }
        });

        if (resource.id) {
            datasource?.update(request as GroupJupiter, 'group_jupiters')
                .then(() => {
                    addSuccess('Le groupe Jupiter a été mis à jour.');
                });
        } else {
            datasource?.create(request as GroupJupiter, 'group_jupiters')
                .then((res) => {
                    if (res instanceof GroupJupiter) {
                        setGroupProjectId(
                            res.id,
                            'groupJupiter',
                        );
                        addSuccess('Le groupe Jupiter a été créé.');
                    }
                });
        }

    } else if (resource instanceof GroupMyrudder) {
        const request = resource.id ? resource : {
            client: true,
            roles: new Array<MyrudderRoles | string>(),
            group: `/api/groups/${isCreatedId}`,
        };
        Object.entries(data).forEach(([property, value]) => {
            if (property in MyrudderRoles) {
                if (!request.roles) {
                    request.roles = [];
                }
                if (value) {
                    if (!request.roles.includes(property.toString())) {
                        request.roles.push(property.toString());
                    }
                } else {
                    if (request.roles.includes(property.toString())) {
                        const roleIndex = request.roles.findIndex((val) => val ===
                            property.toString());
                        request.roles.splice(roleIndex, 1);
                    }
                }
            } else {
                console.warn(`Property "${property}" does not exist on GroupMyrudder. Field was ignored.`);
            }
        });
        request.client = data.client;
        if (resource.id) {
            datasource?.update(request as GroupMyrudder, 'group_myrudders')
                .then(() => {
                    addSuccess('Le groupe Myrudder a été mis à jour.');
                });
        } else {
            datasource?.create(request as GroupMyrudder, 'group_myrudders')
                .then((res) => {
                    if (res instanceof GroupMyrudder) {
                        setGroupProjectId(
                            res.id,
                            'groupMyrudder',
                        );
                        addSuccess('Le groupe Myrudder a été créé.');
                    }
                });
        }
    } else if (resource instanceof GroupWorkflow) {
        const request = resource.id ? resource : {
            roles: new Array<WorkflowRoles | string>(),
            group: `/api/groups/${isCreatedId}`,
        };
        Object.entries(data).forEach(([property, value]) => {
            if (property in WorkflowRoles) {
                if (!request.roles) {
                    request.roles = [];
                }
                if (value) {
                    if (!request.roles.includes(property.toString())) {
                        request.roles.push(property.toString());
                    }
                } else {
                    if (request.roles.includes(property.toString())) {
                        const roleIndex = request.roles.findIndex((val: string) => val ===
                            property.toString());
                        request.roles.splice(roleIndex, 1);
                    }
                }
            } else {
                console.warn(`Property "${property}" does not exist on GroupWorkflow. Field was ignored.`);
            }
        });
        if (resource.id) {
            datasource?.update(request as GroupWorkflow, 'group_workflows')
                .then(() => {
                    addSuccess('Le groupe EasySurf a été mis à jour.');
                });
        } else {
            datasource?.create(request as GroupWorkflow, 'group_workflows')
                .then((res) => {
                    if (res instanceof GroupWorkflow) {
                        setGroupProjectId(
                            res.id,
                            'groupWorkflow',
                        );
                        addSuccess('Le groupe EasySurf a été créé.');
                    }
                });
        }
    } else {
        const body = {
            name: data.name ?? '',
            pool: false,
            contactClientType: data.contactClientType
                ? data.contactClientType.value
                : null,
        };

        body.pool = data.pool;

        datasource.create(body as Group, 'groups').then((res) => {
            if (res instanceof Group) {
                setIsCreatedId(res.id);
                addSuccess('Le groupe a bien été créé');
            }
        });
    }

    loadEnd();
}

const tabs = (
    profile: Group | null,
    jupiter: GroupJupiter | null,
    myrudder: GroupMyrudder | null,
    workflow: GroupWorkflow | null,
    addSuccess: FlashContextInterface['addSuccess'],
    loadStart: () => void,
    loadEnd: () => void,
    isLoading: boolean,
    setIsCreatedId: (id: number) => void,
    isCreatedId: number | null,
    setGroupProjectId: (
        id: number | null,
        groupProject: 'groupWorkflow' | 'groupMyrudder' | 'groupJupiter',
    ) => void,
    groupDatasource: Datasource<Group>,
    groupJupiterDatasource: Datasource<GroupJupiter>,
    groupMyrudderDatasource: Datasource<GroupMyrudder>,
    groupWorkflowDatasource: Datasource<GroupWorkflow>,
) => {
    const disableProjectsGroups: boolean = isCreatedId === null;

    return [
        {
            key: 0, title: {render: () => <>Informations générales</>}, body: {
                render: <FormController
                    namespace={'groups'}
                    normalizer={GroupNormalizer}
                    newResourceCreator={!profile || profile.id === 0
                        ? () => new Group()
                        : undefined}
                    id={!profile || profile.id === 0 ? undefined : profile.id}
                    renderForm={(props) => <>
                        <GroupSettings {...props}/>
                        <FormActions isLoading={isLoading}/>
                    </>}
                    handleSubmitEvent={(profile) => (data) => {
                        UpdateGroup(
                            data,
                            profile,
                            groupDatasource,
                            addSuccess,
                            loadStart,
                            loadEnd,
                            setIsCreatedId,
                            isCreatedId,
                            setGroupProjectId,
                        );
                    }}
                />,
            }, default: true,
        },
        {
            key: 1, title: {render: () => <>Jupiter</>}, body: {
                render: <FormController
                    namespace={'group_jupiters'}
                    normalizer={GroupJupiterNormalizer}
                    newResourceCreator={!jupiter || jupiter.id === 0
                        ? () => new GroupJupiter()
                        : undefined}
                    id={!jupiter || jupiter.id === 0 ? undefined : jupiter.id}
                    renderForm={(props) => <>
                        <JupiterPermissions {...props}/>
                        <FormActions isLoading={isLoading}/>
                    </>}
                    handleSubmitEvent={(jupiter) => (data) => UpdateGroup(
                        data,
                        jupiter,
                        groupJupiterDatasource,
                        addSuccess,
                        loadStart,
                        loadEnd,
                        setIsCreatedId,
                        isCreatedId,
                        setGroupProjectId,
                    )}
                />,
            }, default: false, disabled: disableProjectsGroups,
        },
        {
            key: 2, title: {render: () => <>Myrudder</>}, body: {
                render: <FormController
                    namespace={'group_myrudders'}
                    normalizer={GroupMyrudderNormalizer}
                    newResourceCreator={!myrudder || myrudder.id === 0
                        ? () => new GroupMyrudder()
                        : undefined}
                    id={!myrudder || myrudder.id === 0
                        ? undefined
                        : myrudder.id}
                    renderForm={(props) => <>
                        <MyrudderPermissions {...props}/>
                        <FormActions isLoading={isLoading}/>
                    </>}
                    handleSubmitEvent={(myrudder) => (data) => UpdateGroup(
                        data,
                        myrudder,
                        groupMyrudderDatasource,
                        addSuccess,
                        loadStart,
                        loadEnd,
                        setIsCreatedId,
                        isCreatedId,
                        setGroupProjectId,
                    )}
                />,
            }, default: false, disabled: disableProjectsGroups,
        },
        {
            key: 3, title: {render: () => <>EasySurf</>}, body: {
                render: <FormController
                    namespace={'group_workflows'}
                    normalizer={GroupWorkflowNormalizer}
                    newResourceCreator={!workflow || workflow.id === 0
                        ? () => new GroupWorkflow()
                        : undefined}
                    id={!workflow || workflow.id === 0
                        ? undefined
                        : workflow.id}
                    renderForm={(props) => <>
                        <WorkflowPermissions {...props}/>
                        <FormActions isLoading={isLoading}/>
                    </>}
                    handleSubmitEvent={(workflow) => (data) => UpdateGroup(
                        data,
                        workflow,
                        groupWorkflowDatasource,
                        addSuccess,
                        loadStart,
                        loadEnd,
                        setIsCreatedId,
                        isCreatedId,
                        setGroupProjectId,
                    )}
                />,
            }, default: false, disabled: disableProjectsGroups,
        },
    ];
};

export const AccessProfileType = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const {addSuccess} = useContext(FlashContext);
    const [isLoading, setLoading] = useState(false);

    const [isGroupLoading, setIsGroupLoading] = useState(false);
    const [isGroupJupiterLoading, setIsGroupJupiterLoading] = useState(false);
    const [isGroupMyrudderLoading, setIsGroupMyrudderLoading] = useState(false);
    const [isGroupWorkflowLoading, setIsGroupWorkflowLoading] = useState(false);

    const [isCreatedId, setIsCreatedId] = useState<number | null>(isNaN(
        parseInt(id || ''))
        ? null
        :
        parseInt(id || ''));

    const [group, setGroup] = useState<Group | null>(null);
    const [groupJupiter, setGroupJupiter] = useState<GroupJupiter | null>(null);
    const [groupMyrudder, setGroupMyrudder] = useState<GroupMyrudder | null>(
        null);
    const [groupWorkflow, setGroupWorkflow] = useState<GroupWorkflow | null>(
        null);

    const groupDatasource = useDatasource('groups', GroupNormalizer);
    const groupJupiterDatasource = useDatasource(
        'group_jupiters',
        GroupJupiterNormalizer,
    );
    const groupMyrudderDatasource = useDatasource(
        'group_myrudders',
        GroupMyrudderNormalizer,
    );
    const groupWorkflowDatasource = useDatasource(
        'group_workflows',
        GroupWorkflowNormalizer,
    );

    const {get: getGroup} = useDatasource('groups', GroupNormalizer);
    const {get: getGroupJupiter} = useDatasource(
        'group_jupiters',
        GroupJupiterNormalizer,
    );
    const {get: getGroupMyrudder} = useDatasource(
        'group_myrudders',
        GroupMyrudderNormalizer,
    );
    const {get: getGroupWorkflow} = useDatasource(
        'group_workflows',
        GroupWorkflowNormalizer,
    );

    const setGroupProjectId = (
        id: number | null,
        groupProject: 'groupWorkflow' | 'groupMyrudder' | 'groupJupiter',
    ) => {
        if (group) {
            switch (groupProject) {
                case 'groupWorkflow':
                    setGroup((group) => {
                        return Object.assign(Object.create(Object.getPrototypeOf(
                            group)), {
                            ...group,
                            groupWorkflow: id
                                ? `/api/group_workflows/${id}`
                                : null,
                        });
                    });
                    break;
                case 'groupMyrudder':
                    setGroup((group) => {
                        return Object.assign(Object.create(Object.getPrototypeOf(
                            group)), {
                            ...group,
                            groupMyrudder: id
                                ? `/api/group_myrudders/${id}`
                                : null,
                        });
                    });
                    break;
                case 'groupJupiter':
                    setGroup((group) => {
                        return Object.assign(Object.create(Object.getPrototypeOf(
                            group)), {
                            ...group,
                            groupJupiter: id
                                ? `/api/group_jupiters/${id}`
                                : null,
                        });
                    });
            }
        }
    };

    useEffect(() => {
        if (id && !isNaN(parseInt(id || ''))) {
            setIsGroupLoading(true);
            getGroup(parseInt(id || ''))
                .then(setGroup)
                .then(() => setIsGroupLoading(
                    false));
        }
    }, [getGroup, id]);

    useEffect(() => {
        if (group?.groupJupiter) {
            setIsGroupJupiterLoading(true);
            getGroupJupiter(parseInt(group.groupJupiter.split('/').at(-1) ||
                ''))
                .then(setGroupJupiter)
                .then(() => setIsGroupJupiterLoading(
                    false));
        }
    }, [getGroupJupiter, group]);

    useEffect(() => {
        if (group?.groupMyrudder) {
            setIsGroupMyrudderLoading(true);
            getGroupMyrudder(parseInt(group.groupMyrudder.split('/').at(-1) ||
                ''))
                .then(setGroupMyrudder)
                .then(() => setIsGroupMyrudderLoading(
                    false));
        }
    }, [getGroupMyrudder, group]);

    useEffect(() => {
        if (group?.groupWorkflow) {
            setIsGroupWorkflowLoading(true);
            getGroupWorkflow(parseInt(group.groupWorkflow.split('/').at(-1) ||
                ''))
                .then(setGroupWorkflow)
                .then(() => setIsGroupWorkflowLoading(
                    false));
        }
    }, [getGroupWorkflow, group]);

    const loadStart = () => {
        setLoading(true);
    };
    const loadEnd = () => {
        setLoading(false);
    };

    useEffect(() => {
        if (isCreatedId) {
            navigate(`/profils-acces/${isCreatedId}`);
        }
    }, [isCreatedId, navigate]);

    return (
        <>
            <div className={'mt-1 text-primary'}>
                <Link to={'/profils-acces'}>Retour</Link>
            </div>
            <h1> {group?.name} </h1>
            {
                (isGroupLoading)
                || (isGroupJupiterLoading)
                || (isGroupMyrudderLoading)
                || (isGroupWorkflowLoading) ? <div
                        className={'text-center pt-2 mb-0'}><Spinner/>
                    </div>
                    : <>
                        <Tabs items={tabs(
                            group,
                            groupJupiter,
                            groupMyrudder,
                            groupWorkflow,
                            addSuccess,
                            loadStart,
                            loadEnd,
                            isLoading,
                            setIsCreatedId,
                            isCreatedId,
                            setGroupProjectId,
                            groupDatasource,
                            groupJupiterDatasource,
                            groupMyrudderDatasource,
                            groupWorkflowDatasource,
                        )}/>
                    </>
            }
        </>
    );
};
