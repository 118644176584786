import React from 'react';
import {Form, Table} from 'react-bootstrap';
import CheckboxInput from '../../../forms/fields/CheckboxInput';
import {UseFormRegister, UseFormSetValue} from 'react-hook-form';
import {Group} from '../../../model/Group';
import {WorkflowRoles} from '../../../model/enum/roles/Workflow';
import {JupiterRoles} from '../../../model/enum/roles/Jupiter';
import {MyrudderRoles} from '../../../model/enum/roles/Myrudder';
import {GroupMyrudder} from '../../../model/GroupMyrudder';
import {GroupWorkflow} from '../../../model/GroupWorkflow';
import {GroupJupiter} from '../../../model/GroupJupiter';

type PermissionModuleFields = {
    active?: boolean,
    value?: JupiterRoles | MyrudderRoles | WorkflowRoles,
    name: JupiterRoles | MyrudderRoles | WorkflowRoles | string,
    label?: string,
    dataType?: string,
}

type PermissionType = {
    label: string,
    rights: PermissionModuleFields[],
}

export type PermissionModules = {
    label: string,
    icon?: string,
    permissions: PermissionType[]
}

interface UserPermissionsTableProps<ResourceT extends Group | GroupMyrudder | GroupWorkflow | GroupJupiter> {
    resource: ResourceT
    register: UseFormRegister<any>
    modules: PermissionModules[],
    id: string
    setValue: UseFormSetValue<any>,
}

function inputDataTypeClass(inputName: string): string {
    const splitInputName = inputName.split('_');
    return splitInputName[splitInputName.length - 1];
}

export const AccessProfilePermissionsTable = <ResourceT extends Group | GroupMyrudder | GroupWorkflow | GroupJupiter>({
                                                                                                                          resource,
                                                                                                                          register,
                                                                                                                          modules,
                                                                                                                          id,
                                                                                                                          setValue,
                                                                                                                      }: UserPermissionsTableProps<ResourceT>) => {

    const headCells = [
        {icon: 'fas fa-list', label: 'Consultation', dataType: 'SHOW'},
        {icon: 'fas fa-plus', label: 'Ajout', dataType: 'CREATE'},
        {icon: 'fas fa-edit', label: 'Modification', dataType: 'UPDATE'},
        {icon: 'fas fa-trash', label: 'Suppression', dataType: 'DELETE'},
        {icon: 'fas fa-ellipsis-h', label: 'Autre', dataType: 'OTHER'},
    ];

    const handleChangeAll = (isChecked: boolean, dataType: string) => {
        const inputs = document.querySelectorAll(`#${id} .${dataType} input`);

        inputs.forEach((input) => {
            const inputName = input.getAttribute('name') || '';
            setValue(inputName, isChecked);
        });
    };

    const isChecked = (permission: string) => {
        if (resource instanceof Group || !resource.roles) {
            return;
        }

        return resource.roles.includes(permission);
    };

    const otherPermissions: PermissionModuleFields[] = [];
    modules.forEach((module) => {
        module.permissions.forEach((permission) => {
            permission.rights.forEach((perm) => {
                if (perm.dataType === 'OTHER') {
                    otherPermissions.push(perm);
                }
            });
        });
    });


    return (
        <Table id={id} className={'table-borderless border'}>
            <thead className={'table-light'}>
            <tr>
                <th>Module</th>
                {
                    headCells.map((cell, index) => (
                        <th key={index}>
                            <Form.Check
                                type={'checkbox'}
                                label={<>
                                    <i className={cell.icon}></i>
                                    <span> {cell.label}</span>
                                </>}
                                onChange={(event) => handleChangeAll(
                                    event.target.checked,
                                    cell.dataType,
                                )}
                            />
                        </th>
                    ))
                }
                {
                    otherPermissions.length > 1 ?
                        <th>
                            <Form.Check
                                type={'checkbox'}
                                label={<>
                                    <i className={'fas fa-ellipsis-h'}></i>
                                </>}
                                onChange={(event) => handleChangeAll(
                                    event.target.checked,
                                    'OTHER',
                                )}
                            />
                        </th>
                        : null

                }
            </tr>
            </thead>
            <tbody>
            {
                modules.map((module, index) => (
                    <React.Fragment key={index}>
                        <tr className={'table-info'}>
                            <td colSpan={7}>
                                {
                                    module.icon ?
                                        <i className={module.icon}></i>
                                        : null
                                }
                                <span> {module.label}</span>
                            </td>
                        </tr>
                        {
                            module.permissions.map((
                                permission,
                                moduleIndex,
                            ) => (
                                <tr key={moduleIndex}>
                                    <td>{permission.label}</td>
                                    {
                                        permission.rights.map((perm, index) =>
                                            (<td key={index}>
                                                {
                                                    perm.active ?
                                                        <div
                                                            className={'d-flex'}>
                                                            <CheckboxInput
                                                                name={perm.name}
                                                                checked={isChecked(
                                                                    perm.name)}
                                                                register={register}
                                                                inputClasses={inputDataTypeClass(
                                                                    perm.name)}
                                                            />
                                                            <p>&nbsp;{perm.label ??
                                                                null}
                                                            </p>
                                                        </div>
                                                        : null
                                                }
                                            </td>),
                                        )
                                    }
                                </tr>
                            ))
                        }

                    </React.Fragment>
                ))
            }
            </tbody>
        </Table>
    );
};