import React from 'react';
import {renderFormProps} from '../../../FormController';
import TextInput from '../../../fields/TextInput';
import Form from 'react-bootstrap/Form';
import {ControlSwitchInput} from '../../../../components/SwitchButton';
import {FieldErrors, UseFormWatch} from 'react-hook-form';
import SelectInput, {SelectInputOptions} from '../../../fields/SelectInput';
import {UserMyrudder} from '../../../../model/UserMyrudder';

export interface UserMyrudderProps extends renderFormProps<UserMyrudder> {
    resource: UserMyrudder;
    errors: FieldErrors;
    watch: UseFormWatch<any>;
}

export const MyrudderSettings = ({
    register,
    resource,
    control,
}: UserMyrudderProps) => {

    const languageOptions: SelectInputOptions[] = [
        {
            label: 'Français',
            value: 'fr',
        },
        {
            label: 'Anglais',
            value: 'en',
        },
    ];

    return (
        <div className={'border-bottom ms-0 me-5 pt-3 pb-5 '}>
            <div className={'form-group mb-3 col-10'}>
                <Form.Label>Admin Derudder</Form.Label>
                <ControlSwitchInput
                    classes={'form-switch-lg mb-1'}
                    control={control}
                    name={'admin'}
                    register={register}
                    value={resource.admin}
                />
                <p>Si coché, alors l'utilisateur verra toutes les factures
                    de
                    tous les clients</p>
            </div>
            <TextInput
                containerClass={'form-group mb-3'}
                label={'Téléphone'}
                name={'phone'}
                register={register}
                control={control}
                resourceValue={resource.phone}
            />
            <SelectInput name={'language'} control={control}
                         containerClass={'form-group mb-3'}
                         register={register}
                         selectOptions={languageOptions} label={'Langue'}
                         placeholder={'Sélectionner un langue'}
                         defaultValue={resource.language}
            />

            <div className={'form-group mb-3 col-2'}>
                <Form.Label>Activé ?</Form.Label>
                <ControlSwitchInput
                    classes={'form-switch-lg mb-1'}
                    control={control}
                    name={'enabled'}
                    register={register}
                    value={resource.enabled}
                />
            </div>

        </div>
    );
};