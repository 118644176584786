import {Card, Nav, Tab} from 'react-bootstrap';
import {ReactNode} from 'react';
import classNames from 'classnames';

interface TabHeaderType {
    containerClass?: string;
    render: () => ReactNode;
}

interface TabContentType {
    containerClass?: string;
    render: (() => ReactNode) | ReactNode;
}

interface TabItemType {
    key: number;
    title: TabHeaderType;
    body: TabContentType;
    default?: boolean;
    disabled?: boolean;
}

export const Tabs = ({
    items,
    title,
}: { items: TabItemType[], title?: string }) => {
    const defaultItem = (items.find(el => el.default));

    return (
        <Card className={'bg-white h-100 overflow-hidden mb-0'}>
            <Card.Body
                className={'d-flex flex-column flex-fill overflow-hidden'}>
                {
                    title ? (
                        <h4 className="header-title mb-4">{title}</h4>
                    ) : null
                }

                <Tab.Container defaultActiveKey={defaultItem
                    ? defaultItem.key
                    : items[0].key}>
                    <Nav as="ul" variant="tabs" className="nav-bordered">
                        {(items).map((tab, index) => {
                            return (
                                <Nav.Item as="li" key={index}>
                                    <Nav.Link className={classNames(
                                        'cursor-pointer' +
                                        (tab.disabled ? ' disabled' : ''),
                                        tab.title.containerClass,
                                    )} eventKey={tab.key} as={'button'}
                                              disabled={tab.disabled}>
                                        {tab.title.render()}
                                    </Nav.Link>
                                </Nav.Item>
                            );
                        })}
                    </Nav>

                    <Tab.Content className={'d-flex flex-column flex-fill'}
                                 style={{
                                     overflowY: 'auto',
                                     overflowX: 'hidden',
                                 }}>
                        {(items || []).map((tab, index) => {
                            return (
                                <Tab.Pane eventKey={tab.key}
                                          id={String(tab.key)} key={index}>
                                    {typeof tab.body.render === 'function'
                                        ? tab.body.render()
                                        : tab.body.render}
                                </Tab.Pane>
                            );
                        })}
                    </Tab.Content>
                </Tab.Container>
            </Card.Body>
        </Card>
    );
};
