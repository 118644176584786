import {Identifiable} from '../model/interface/Identifiable';
import {useContext, useMemo} from 'react';
import {TokenContext} from '../context/TokenContext';
import axios from 'axios';
import {Normalizer} from '../services/normalizer/normalizer';
import {
    FlashContext,
} from '../context/FlashContext';

export type FilterType = {
    [key: string]: any
}

export type OrderType = {
    [key: string]: 'ASC' | 'DESC' | null
}

export interface Datasource<T extends Identifiable> {
    index(
        filters?: FilterType,
        orders?: OrderType,
        controller?: AbortController,
    ): any;

    update(resource: T, context: any): Promise<boolean>;

    create(resource: T, context: any): Promise<T | false>;

    delete?(resource: T): Promise<boolean>;

    get(id: T['id'], controller?: AbortController): Promise<T>;
}

const transform = (data: any) =>
    (acc: object, key: string) =>
        ({...acc, ...{[key.replaceAll('+', '.')]: data[key]}});

export default function useDatasource<ResourceT extends Identifiable>(
    namespace: string,
    normalizer: Normalizer<ResourceT>,
): Datasource<ResourceT> {
    const {token} = useContext(TokenContext);
    const {addError} = useContext(FlashContext);

    return useMemo(() => {

        const headers = {
            Accept: 'application/ld+json',
            Authorization: 'Token ' + token,
        };

        const debug =/* process.env.NODE_ENV === 'development'
            ? 'app_dev.php/'
            : */'';

        axios.defaults.headers.post['Content-Type'] = 'application/json';
        axios.defaults.headers.patch['Content-Type'] = 'application/merge-patch+json';
        axios.defaults.headers.common['Authorization'] = 'Token ' + token;

        return ({
            index(
                filters?: FilterType,
                order?: OrderType,
                controller?: AbortController,
            ): any {

                const transformedFilters = filters ? Object.keys(filters)
                    .reduce(transform(filters), {}) : {};

                return axios.get(`/${debug}api/${namespace}`, {
                    headers: headers,
                    params: {...transformedFilters, order: {...order}},
                    signal: controller ? controller.signal : undefined,
                }).then((res) => {
                    return [
                        normalizer.normalize
                            ? res.data['hydra:member'].map(normalizer.normalize)
                            : res.data['hydra:member'],
                        res.data['hydra:totalItems'],
                        res.data['hydra:view']];
                });
            },
            get(
                id: ResourceT['id'],
                controller?: AbortController,
            ): Promise<ResourceT> {
                return axios.get(`/${debug}api/${namespace}/${id}`, {
                    headers: headers,
                    signal: controller ? controller.signal : undefined,
                }).then((res) => {
                    return normalizer.normalize
                        ? normalizer.normalize(res.data)
                        : res.data;
                });
            },
            update(resource, context) {
                const instance = axios.create();
                return instance
                    .patch(
                        `/${debug}api/${namespace}/${resource.id}`,
                        normalizer.serialize ? normalizer.serialize(
                            resource,
                            context,
                        ) : resource,
                    )
                    .then(
                        (res) => {
                            return normalizer.normalize ? normalizer.normalize(
                                res.data) : res.data;
                        },
                    )
                    .catch(
                        (err) => {
                            const message: string = err.response.data.detail ??
                                'Une erreur est survenue.';
                            addError(message);
                            return false;
                        },
                    );
            },
            create(resource, context) {
                const instance = axios.create();
                return instance
                    .post(
                        `/${debug}api/${namespace}`,
                        normalizer.serialize ? normalizer.serialize(
                            resource,
                            context,
                        ) : resource,
                    )
                    .then((res) => {
                        return normalizer.normalize
                            ? normalizer.normalize(res.data)
                            : res.data;
                    })
                    .catch((err) => {
                        const message: string = err.response.data.detail ??
                            'Une erreur est survenue.';
                        addError(message);
                        return false;
                    });
            },
        });
        // eslint-disable-next-line
    }, [namespace, normalizer, token]);
}