export enum JupiterRoles {
    ROLE_JUPITER_USER_SHOW = 'ROLE_JUPITER_USER_SHOW',
    ROLE_JUPITER_USER_CREATE = 'ROLE_JUPITER_USER_CREATE',
    ROLE_JUPITER_USER_EDIT = 'ROLE_JUPITER_USER_EDIT',
    ROLE_JUPITER_USER_DELETE = 'ROLE_JUPITER_USER_DELETE',
    ROLE_JUPITER_ACCESS_PROFILE_SHOW = 'ROLE_JUPITER_ACCESS_PROFILE_SHOW',
    ROLE_JUPITER_ACCESS_PROFILE_CREATE = 'ROLE_JUPITER_ACCESS_PROFILE_CREATE',
    ROLE_JUPITER_ACCESS_PROFILE_EDIT = 'ROLE_JUPITER_ACCESS_PROFILE_EDIT',
    ROLE_JUPITER_ACCESS_PROFILE_DELETE = 'ROLE_JUPITER_ACCESS_PROFILE_DELETE',
}
