import React from 'react';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import Panel from './Panel/Panel';
import LeftSidebar from './Header/LeftSideBar';
import {RootState} from '../redux/store';
import {useSelector} from 'react-redux';
import {changeBodyAttribute} from '../utils';

type Props = {
    children: React.ReactNode;
    handleLogout: () => void;
}

function TriptychLayout({children, handleLogout}: Props) {

    const {leftSideBarTheme, leftSideBarType} =
        useSelector((state: RootState) => ({
            leftSideBarTheme: state.Layout.leftSideBarTheme,
            leftSideBarType: state.Layout.leftSideBarType,
        }));

    const [isMenuOpened, setIsMenuOpened] = React.useState<boolean>(false);

    React.useEffect(() => {
        changeBodyAttribute('data-leftbar-color', leftSideBarTheme);
    }, [leftSideBarTheme]);

    React.useEffect(() => {
        changeBodyAttribute('data-leftbar-size', leftSideBarType);
    }, [leftSideBarType]);

    /**
     * Open the menu when having mobile screen
     */
    const openMenu = () => {
        setIsMenuOpened((prevState) => !prevState);
        if (document.body) {
            if (isMenuOpened) {
                document.body.classList.add('sidebar-enable');
            } else {
                document.body.classList.remove('sidebar-enable');
            }
        }
    };

    // const [root] = routes;
    // const parametersRouteChildren: RoutesProps[] = [];
    // root.children && root.children.forEach((value, index) => {
    //     parametersRouteChildren.push(value);
    // })
    return (
        <div className="vh-100 d-flex flex-column"
             // style={{
             //     backgroundImage: `url(${bgDerudder})`,
             //     backgroundRepeat: "no-repeat",
             //     backgroundPosition: "bottom"
             // }}
        >
            <Header openLeftMenuCallBack={openMenu}
                    handleLogout={handleLogout}/>
            <div className="position-relative bg-transparent d-flex flex-fill overflow-hidden">
                <LeftSidebar/>
                <div
                    className={'bg-transparent d-flex flex-column flex-fill px-2 pb-2 pt-0 overflow-hidden'}>
                    <Panel>
                        {children}
                    </Panel>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default TriptychLayout;
