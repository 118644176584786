import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Dropdown} from 'react-bootstrap';
import classNames from 'classnames';

interface ProfileMenuItem {
    label: string;
    icon: string;
    redirectTo?: string;
    onClick?: () => void;
}

interface ProfileDropdownProps {
    menuItems: Array<ProfileMenuItem>;
    profilePic?: string;
    username: string;
    userTitle?: string;
}

const ProfileDropdown = (props: ProfileDropdownProps) => {
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

    const fullname = localStorage.getItem('fullname');
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    return (
        <Dropdown show={dropdownOpen} onToggle={toggleDropdown}>
            <Dropdown.Toggle
                id="dropdown-profile"
                as="a"
                onClick={toggleDropdown}
                style={{
                    color: 'var(--ct-text-color-h)'
                }}
                className={classNames(
                    'nav-link nav-user me-0 waves-effect waves-light',
                    {show: dropdownOpen},
                )}
            >
                <span className="pro-user-name ms-1 text-primary">
                   Mon Compte <i className="mdi mdi-chevron-down"></i>
                </span>
            </Dropdown.Toggle>
            <Dropdown.Menu
                className="dropdown-menu dropdown-menu-end profile-dropdown bg-white">
                <div onClick={toggleDropdown}>
                    <div className="dropdown-header noti-title text-primary">
                        <p className="text-overflow m-0">{`Bonjour ${fullname}`}</p>
                    </div>
                    {(props.menuItems || []).map((item: ProfileMenuItem, i) => {
                        return (
                            <React.Fragment key={i}>
                                {i === props['menuItems'].length - 1 &&
                                    <div className="dropdown-divider"></div>}
                                {
                                    item.redirectTo ? (
                                        <Link
                                            to={item.redirectTo}
                                            className="dropdown-item notify-item"
                                            key={i + '-profile-menu'}
                                        >
                                            <i className={`${item.icon} me-1`}></i>
                                            <span>{item.label}</span>
                                        </Link>
                                    ) : (
                                        <Button onClick={item.onClick}
                                                variant={'link'}>
                                            <i className={`${item.icon} me-1`}></i>
                                            <span>{item.label}</span>
                                        </Button>
                                    )
                                }
                            </React.Fragment>
                        );
                    })}
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default ProfileDropdown;
