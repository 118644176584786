import React, {useContext} from 'react';
import ButtonComponent from '../../ButtonComponent';
import {ResourceListingActionProps} from '../../TableListing';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {FlashContext} from '../../../context/FlashContext';
import {Button, Dropdown, Modal} from 'react-bootstrap';
import {Identifiable} from '../../../model/interface/Identifiable';
import {APICore} from '../../../services';
import {User} from "../../../model/User";
import {TokenContext} from "../../../context/TokenContext";
import axios from "axios";

const api = new APICore();

type switchUser = {
    target: string | undefined;
    resource: 'myrudder' | 'workflow' | 'jupiter',
    email: string;
    token: string | null;
    label: string;
}

interface ListingActionsProps<ResourceT extends Identifiable> extends ResourceListingActionProps<ResourceT> {
}

export default function ResourceListingActions<ResourceT extends Identifiable>({
                                                                                   resource,
                                                                                   deleteResource,
                                                                                   duplicateRoute,
                                                                                   connectAs,
                                                                               }: ListingActionsProps<ResourceT>) {
    const [show, setShow] = React.useState<boolean>(false);
    const {addSuccess} = useContext(FlashContext);
    const location = useLocation();
    const navigate = useNavigate();
    const {token} = useContext(TokenContext);

    const removeRessource = async (data: ResourceT) => {
        let baseUrl = '';
        switch (location.pathname) {
            case '/profils-acces':
                baseUrl = '/groups';
                break;
            case '/utilisateurs':
                baseUrl = '/users';
                break;
        }
        await api.delete(`/api${baseUrl}/${data.id}`).then(() => {
            addSuccess(`L'élément a bien été supprimé`);
            navigate(location.pathname);
        });
    };

    const debug = /*process.env.NODE_ENV === 'development'
        ? 'app_dev.php/'
        : */'';

    const hydrateSwitchButtons = (user: User): switchUser[] => {
        const links = Array<switchUser>();
        if (user.userMyrudder) links.push({
            target: process.env.REACT_APP_MYRUDDER_APP_URL,
            resource: 'myrudder',
            email: user.email,
            token: token,
            label: "MyRudder"
        });
        if (user.userWorkflow) links.push({
            target: process.env.REACT_APP_WORKFLOW_APP_URL,
            resource: 'workflow',
            email: user.email,
            token: token,
            label: "EasySurf"
        });

        return links;
    }

    const triggerSwitch = (link: switchUser) => {
        const switchApiUrl = 'api/users/switch-user';
        const body = JSON.stringify({
            email: link.email,
            resource: link.resource,
        });
        if (link.resource === 'workflow') {
            axios
                .post(`${process.env.REACT_APP_MYRUDDER_APP_URL}/${debug + switchApiUrl}`,
                    body,
                    {
                        headers: {
                            Authorization: `Token ${token}`
                        },
                    })
                .then((response) => {
                    if (response.status === 200) {
                        window.open(`${link.target}?api_token=${response.data.token}`, '_blank');
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }

    }

    const closeModal = () => setShow(false);
    const openModal = () => setShow(true);

    return (
        <div className={'d-flex'}>
            <div className="col-3">
                <Link to={`${location.pathname}/${resource.id}`}
                      className={'btn btn-warning text-light'}>
                    <i className={'fas fa-edit'}></i>
                </Link>
            </div>
            {
                duplicateRoute && (
                    <div className={'col-3'}>
                        <Link to={location.pathname + duplicateRoute + resource.id}
                              className={'btn btn-info text-light'}>
                            <i className={'fas fa-copy'}></i>
                        </Link>
                    </div>

                )
            }
            <div className={'col-3'}>
                <ButtonComponent
                    buttonIcon={'fas fa-trash-alt'}
                    classes={'btn-danger'}
                    handleClickEvent={() => openModal()}
                />
            </div>
            <Modal show={show} onHide={() => closeModal()}>
                <Modal.Header closeButton>
                    <Modal.Title>Attention</Modal.Title>
                </Modal.Header>
                <Modal.Body>Etes-vous sûr de vouloir supprimer cet élément
                    ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => closeModal()}>
                        Annuler
                    </Button>
                    <Button variant="danger"
                            onClick={() => {
                                removeRessource(resource);
                                deleteResource(resource);
                                closeModal();
                                addSuccess(`Elément supprimé avec succès !`);
                            }}
                    >
                        Confirmer
                    </Button>
                </Modal.Footer>
            </Modal>
            {
                connectAs ? (
                    <Dropdown className="col-3 d-inline-block">
                        <Dropdown.Toggle id={'dropdown-basic'}>
                             <span className="pro-user-name">
                                 <i className={'fas fa-power-off'}></i> <i
                                 className="mdi mdi-chevron-down"></i>
                            </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            className="dropdown-menu profile-dropdown bg-white">
                            {
                                resource instanceof User ? hydrateSwitchButtons(resource).map((link, index) => (
                                    <div key={index}>
                                        {
                                            link.resource === 'workflow' ? <Button variant="light"
                                                                                   className={'btn-block'}
                                                                                   onClick={() => {
                                                                                       triggerSwitch(link);
                                                                                   }
                                                                                   }>Se
                                                    connecter sur &nbsp; {link.label}</Button> :
                                                <Link
                                                    to={`${process.env.REACT_APP_MYRUDDER_APP_URL}/${debug}?_switch_user=${link.email}`}
                                                    target={'_blank'}>
                                                    <Button variant="light"
                                                            className={'btn-block'}>
                                                        Se connecter sur &nbsp; {link.label}
                                                    </Button>
                                                </Link>
                                        }
                                    </div>
                                )) : null
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                ) : null
            }
        </div>
    );
}