import {normalizedType, Normalizer} from './normalizer';
import {User} from '../../model/User';

const UserNormalizer = new class implements Normalizer<User> {

    normalize(data: normalizedType<User>): User {
        const resource = new User(
            data.id,
            data.firstname,
            data.lastname,
            data.email,
            data.lastLogin,
            data.groups,
        );

        resource.trafficCodes = data.trafficCodes;
        resource.clients = data.clients;
        resource.hiddenClients = data.hiddenClients;
        resource.plainPassword = data.plainPassword;
        resource.userJupiter = data.userJupiter;
        resource.userMyrudder = data.userMyrudder;
        resource.userWorkflow = data.userWorkflow;

        return resource;
    }
}();

export {UserNormalizer};