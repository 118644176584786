import {Identifiable} from './interface/Identifiable';
import {JupiterRoles} from './enum/roles/Jupiter';

export class GroupJupiter implements Identifiable {
    roles: Array<JupiterRoles | string> = [];
    group: string | null = null;

    constructor(
        readonly id: number = 0,
    ) {
    }
}
