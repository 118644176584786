import React from 'react';
import {renderFormProps} from '../../../FormController';
import Form from 'react-bootstrap/Form';
import {ControlSwitchInput} from '../../../../components/SwitchButton';
import {FieldErrors, UseFormWatch} from 'react-hook-form';
import {UserJupiter} from '../../../../model/UserJupiter';

export interface UserJupiterProps extends renderFormProps<UserJupiter> {
    resource: UserJupiter;
    errors: FieldErrors;
    watch: UseFormWatch<any>;
}

export const JupiterSettings = ({
    register,
    resource,
    control,
}: UserJupiterProps) => {

    return (
        <div className={'border-bottom ms-0 me-5 pt-3 pb-5 '}>
                <div className={'form-group mb-3 col-2'}>
                    <Form.Label>Activé ?</Form.Label>
                    <ControlSwitchInput
                        classes={'form-switch-lg mb-1'}
                        control={control}
                        name={'enabled'}
                        register={register}
                        value={resource.enabled}
                    />
                </div>
        </div>
    );
};